.learningHero {
  background: url("../../images/learningmanage/banner.webp");
}
.learningmanage h2 {
  font-size: 28px;
}

.learning-exp img {
  width: 60%;
  margin: 0 auto;
  display: block;
  height: auto;
}
.learning-exp {
  margin-top: -5em;
  background-color: #fff;
  margin-bottom: 20px;
}
.learningExpertise h4:after {
  display: none;
}
.learningExpertise p {
  white-space: nowrap;
}
.process_circle {
  height: 350px;
  width: 350px;
  border-radius: 50%;
  border: 2px solid #707070;
  margin: 0 auto 1em auto;
  position: relative;
}
.learning-process-img {
  display: block;
  margin: -50px auto 0 auto;
  width: 100%;
}
.learning-pro-btn:after {
  content: url("../../images/learningmanage/Polygon.png");
  top: -8px;
    position: absolute;
    transform: rotate(
235deg
);
    left: 110px;
}
.learning-pro-btn:before{
    content: '01';
    color: #8e8e8e29;
    font-size: 43px;
    position: absolute;
    top:-40%;
    left:-50%;
}
.learning-pro-btn-2:after {
  content: url("../../images/learningmanage/Polygon.png");
  top: 95px;
  position: absolute;
  transform: rotate(-60deg);
  left: 135px;
}
.learning-pro-btn-2:before{
    content: '02';
    color: #8e8e8e29;
    font-size: 43px;
    position: absolute;
    top:-50%;
    left:-20%;
}
.learning-pro-btn-3:after {
  content: url("../../images/learningmanage/Polygon.png");
  top: 125px;
  position: absolute;
  transform: rotate(370deg);
  left: 20px;
}
.learning-pro-btn-3:before{
    content: '03';
    color: #8e8e8e29;
    font-size: 43px;
    position: absolute;
    top:-60%;
    left:70%;
}
.learning-pro-btn-4:after {
  content: url("../../images/learningmanage/Polygon.png");
  top: 90px;
  position: absolute;
  transform: rotate(60deg);
  left: -60px;
}
.learning-pro-btn-4:before{
    content: '04';
    color: #8e8e8e29;
    font-size: 43px;
    position: absolute;
    top:20%;
    right:-60%;
}
.learning-pro-btn-5:after {
  content: url("../../images/learningmanage/Polygon.png");
  top: -8px;
  position: absolute;
  transform: rotate(125deg);
  left: -35px;
}
.learning-pro-btn-5:before{
    content: '05';
    color: #8e8e8e29;
    font-size: 43px;
    position: absolute;
    top:80%;
    right:-50%;
}
.learning-pro-btn-6:after {
  content: url("../../images/learningmanage/Polygon.png");
  top: -55px;
    position: absolute;
    transform: rotate(
185deg
);
    left: 38px;
}
.learning-pro-btn-6:before{
    content: '06';
    color: #8e8e8e29;
    font-size: 43px;
    position: absolute;
    top:50%;
    left:-60%;
}
.readmore_btn {
  background: none;
  color: #2579d8;
  border: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}
.learning-mid-img img {
    width: 100%;
    margin: 0 auto;
    display: block;
}
.learningExpertise img{
    height: auto;
    width: auto;
    max-height:50px;
}
.collapse-div {
  display: contents;
}

.learningmanage {
  background: #ffffff;
}
.collapse-btn-services {
  background: none;
  border: none;
  box-shadow: none;
  margin-bottom: -20px;
}

.collapse-btn-services svg {
  color: #fff;
  background: #ccc;
  border-radius: 50%;
  height: 1.4rem;
  width: 1.4rem !important;
  padding: 2px;
  vertical-align: middle;
  margin: 0 5px;
}

.learningbgInfo {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../images//learningmanage/learningbg.webp");
  padding: 10%;
  color: #fff;
  background-size: cover;
}
.learningbgInfo p {
  font-size: 2.5vw;
}
.learning-cap-img {
  width: 90%;
  margin-left: 100px;
  margin-top: -100px;
}
.activeLearning {
  background: #2579d8 !important;
  font-weight: bold;
}

@media (max-width: 500px) {
  .digicard {
    width: 100%;
  }
  .devheading {
    font-size: 16px;
  }
  .devparagraph {
    font-size: 14px;
  }
  .learning-cap-img {
    margin-top: 0;
  }
  .portal-capable-content li {
    white-space: inherit;
    text-align: left;
  }
  .footerheading {
    font-size: 17px;
  }
  .footerparagraph{
    line-height: 1.9;
    margin-bottom: 15px;
  }
  .emaster-section .container .detail-section .detail h3 {
    font-size: 14px;
    padding-top: 15px;
    line-height: 1.8;
  }
  .emaster-section .container .detail-section .image-detail img {
    width: 100% !important;
  }
 
  .portal-exp-icon{
      height: 90px;
      width: 90px;
  }
  .emaster-section{
      padding-top: 10px !important;
  }
  .hero-styles h4{
      font-size: 16px !important;
      line-height: 1.9;
  }
}
@media (max-width: 768px) {
  .learning-cap-img {
    margin-top: -40px !important;
  }
  .mcontainerpadding {
    padding-top: 10px;
  }
  .exp-img div img {
    max-width: 100%;
  }
  .learningExpertise p {
    white-space: inherit;
    font-size: 12px;
    padding:5px;
  }
  .mparagraphheading,
  .mparagraphheadingtop {
    font-size: 16px;
  }
  .footerbottom p {
    font-size: 10px !important;
  }
  .portal-capable-content {
    width: 100% !important;
  }
  .emaster-section .container .detail-section .detail h3 {
    line-height: 1.8;
  }
  .platcard{
      margin:15px 0;
  }
}

@media (max-width: 992px) {
  .emaster-section .container .detail-section .image-detail img {
    width: 80%;
  }
}
