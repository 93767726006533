
.workflowProBtn{
    background-color: #7B7D7E;
    color: #fff;
    font-size: 15px;
    padding:5px 10px;
    display: block;
    border-radius: 40px;
    text-align: center;
    margin: 0px auto 40px auto;
    width: 45%;
    position: relative;
    z-index: 10;
    cursor: pointer;
}
.workflowProBtn.active, .workflowProBtn-2.active{
    background: #2579D8;
    font-weight: bold;
}
.workflowProBtn::before{
    content: "";
    background: url("../../images/workflowAutomation/arrow.png");
    height: 75px;
    width: 8%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
   left: -30px;
   top:10px;
   z-index: 0;
}
.workflowProBtn::after{
    content: "01";
    font-size: 40px;
    position: absolute;
    left:-80px;
    color:#7c7c7c;
    top:-10px;
    font-weight: bold;
    opacity: 0.6;
}
.wbtn-3::after{
    content: "03";
}

.workflowProBtn-2{
    background-color: #7B7D7E;
    color: #fff;
    font-size: 15px;
    padding:5px 10px;
    display: block;
    border-radius: 40px;
    text-align: center;
    margin: 0 auto 40px auto;
    width: 45%;
    position: relative;
    z-index: 10;
    cursor: pointer;
}
.workflowProBtn-2::before{
    content: "";
    background: url("../../images/workflowAutomation/arrow\ \(2\).png");
    height: 75px;
    width: 8%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
   right: -30px;
   top:10px;
   z-index: 0;
}
.workflowProBtn-2::after{
    content: "02";
    font-size: 40px;
    position: absolute;
    right:-80px;
    color:#7c7c7c;
    top:-10px;
    font-weight: bold;
    opacity: 0.6;
}
.wbtn-4::after{
    content: "04";
}
.wbtn-5::after{
    content: "05";
}
.wbtn-5::before{
    content: "";
    background: none;
}
.workflowhero{
    background:linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)),  url('../../images/workflowAutomation/rpa-robotic-process-automation-artificial-intelligence-technology-154440557.png') center center no-repeat;

}
.workflowbg{
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),url("../../images/workflowAutomation/sae-port-24067057.png") !important;
}

@media (max-width:650px){
    .wf-exp{
        margin-top: 85px;
    }
}
.workflow{
   
    margin:-20px auto 20px auto;
    display: block;
}