.campaignhero{
    background:linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)),  url('https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/businessman-touching-email-icon-30028148.png');
    width: 100%;
    float: left;
    background-size: cover;
    background-repeat: no-repeat;

}
.campaignbg{
    background: linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)),url('https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/digital-marketing-media-website-ad-email-social-network-seo-video-mobile-app-in-virtual-globe-shape-diagram-124276305.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    
}
.campaignbg p{
    font-size: 34px;
}

.campaignbg .mcontent {
    color: #fff;
    text-align: left;
    padding: 100px 98px;
}
.campaignExpertise, .campaignExpertise-first{
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    width: 100%;
    padding: 20px;
    background-color: #fcfcfc;
    margin-bottom: 30px;
    border-radius: 20px;

}
.campaignExpertise h4, .campaignExpertise-first h4{
    text-align: center;
    width: 100%;
    font-size: 22px;
    color: #7c7c7c;
    font-weight: bold;
}
.campaignExpertise-first h4::after{
    width: 10%;
    content:"";
    height:4px;
    background-color: #ff4805;
    
    display: block;
    margin:0 auto;
    margin-top: 10px;
}
    

.campaignExpertise h4::after{
    content:"";
    height:4px;
    background-color: #ff4805;
    width: 50%;
    display: block;
    margin:0 auto;
    margin-top: 10px;
}
.campaignExpertise img,.campaignExpertise-first img{
    display: block;
    margin:0 auto;
    height: 60px;
}
.campaignExpertise p,.campaignExpertise-first p{
    text-align: center;
    color: #7c7c7c;
    padding:10px;
}
.camp_process{
    width:100%;
}

@media (max-width:700px){
    .campaignbg .mcontent{
        padding: 50px 20px;
        
    }
    .campaignbg .mcontent p{
        font-size: 26px;
    }
}
