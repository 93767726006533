
.navbar {
  background-color: #fff;
  border-top: 15px solid #2579d8;

  .navbar-brand {
      margin-left: 4%;
    img {
      width: 100px;
    }
  }

  .navbar-nav {
    .nav-link {
     
      font-size: 18px;
      color: #2579d8 !important;
      transition: all 500ms 0s ease;
      text-align: center;
      margin-left: 1.5rem;

      .searchicon {
        font-size: 21px;
        color: black !important;
        border-left: 1px solid #333;
        width: 5rem;
      }

    }
    
  }

  
}


