.our-project-section {
    .container-fluid {
        background-color: #ffffff;
       padding:7% 0 5% 0;
        opacity: 0.88;
        position: relative;
        width: 100%;
        overflow: hidden;
        min-height: 400px;

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: 0;
            -ms-transform: translateX(-50%) translateY(-50%);
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
            opacity: 0.7;
        }

        .text-over {
            z-index: 1;
            font-size: 18px;
            
            background-color: #428ADBDE;
            width: 55%;
            margin-left: -14px;
            padding: 44px;
            min-height: 240px;
            color: white !important;
            position: absolute;

            p {
                font-size: 16px;
            }

            .btn-default {
                font-size: 16px;
                color: white;
                background-color: transparent !important;
                padding:5px 15px;
                outline: none !important;
                border: 2px solid white;
                margin-top: 14px;
            }
        }

        @media only screen and (min-device-width: 280px) and (max-device-width: 640px) {
            .text-over {
                width: 100%;
                margin-left: 0;

                .btn-default {
                    width: 241px;
                }
            }
        }
    }
}