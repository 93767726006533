.emaster-section {
    background-image: url("../../../images/mobilebg.png");

    .container {
       

        .detail-section {
            background-image:  linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),  url("https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/bg3-_1_\ \(1\).webp");
            padding: 2rem 2rem;
            background-size: cover;

            .detail {
                background: #428ADBDD 0% 0% no-repeat padding-box;
                opacity: 1;
                color: #fff;
                padding: 2rem 3rem;
                align-items: center;
                align-content: center;
                margin-bottom: 1rem;

                h3 {
                    font-weight: 200;
                    font-size: 18px;
                }

                p {
                    margin-top: 2rem;
                    margin-bottom: 1rem;
                    letter-spacing: 0px;
                    font-weight: 200;
                    font-size: 15px;
                    line-height: 2.1;
                }

                .badge-section {
                    .badge-danger {
                        color: #fff;
                        background-color: #FF4805;
                        margin-right: 1rem;
                        margin-top: 0.5rem;
                        padding: 0.5rem;
                        border-radius: 10px;
                        font-weight: normal;
                        white-space:pre-wrap;
                    }
                }
            }

            .image-detail {
                text-align: center;
            }
            .image-detail img{
                width: 55%;
            }
        }
    }



   
}