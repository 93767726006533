.uihero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../images/uiux-deliver/wireframing-stage-of-a-web-design-project-147007588.png");
  width: 100%;
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
}
.ui_deliver {
  margin: 0;
  padding: 0;
  width: 220px;
}
.ui-deliver-row {
  padding-left: 2%;
}
.ui-caption {
  width: 160px;
  text-align: left;
  display: flex;
}
.ui_deliver li {
  background-image: url("../../images/uiux-deliver/Icon\ material-check-circle.svg");
  padding: 5px 0 5px 30px;
  list-style: none;
  color: #7c7c7c;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
}
.ui_deliver li img {
  width: 20px;
}
.ui_blue {
  font-size: 16px;
  color: #2579d8;
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
}
.ui_caption {
  height: 100px;
  display: table;
}
.ui-num {
  font-size: 70px;
  color: #ff480529;
  display: block;
  text-align: left;
}
.ui-mob-image {
  width: 190%;
  height: 580px;
  margin-left: -90%;
  margin-top: 0;
}

/* second */
.ui-deliver-row-1 {
  padding: 10% 0 0 10%;
}

/* third  */
.ui-deliver-row-2 {
  padding: 10% 0 0 20%;
}

/* fourth */
.ui-deliver-row-3 {
  padding: 10% 0 0 30%;
}
.ui_deliver_row,
.ui-deliver-row-2 {
  max-width: 700px;
}
.ui-deliver-row-1 {
  max-width: 650px;
}
.ui-deliver-row-3 {
  max-width: 800px;
}

.uiProcessBg img {
  margin: 0 auto;
  width: 100%;
}
.uiProcessbtn button {
  background-color: #2579d8;
  height: 60px;
  width: 200px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  border: none;
  display: block;
  margin-left: 30%;
}
.uiLine {
  border-left: 2px dashed #707070;
  height: 50px;
  margin-left: 60%;
  margin-bottom: -20px;
}
.uiLine-btm{
    border-left: 2px dashed #707070;
  height: 80px;
  margin-left: 60%;
  margin-bottom: -20px;
  margin-top: -25px;
}
.uiLine2 {
    border-left: 2px dashed #707070;
    height: 50px;
    margin-left: 59%;
    margin-bottom: -20px;
    
  }
  .uiLine-btm2 {
    border-left: 2px dashed #707070;
    height: 80px;
    margin-left: 59%;
    margin-bottom: -20px;
    margin-top: -25px;
  }
  .uiLine3 {
    border-left: 2px dashed #707070;
    height: 50px;
    margin-left: 50%;
    margin-bottom: -20px;
  }
  .uiLine-btm3 {
    border-left: 2px dashed #707070;
    height: 80px;
    margin-left: 50%;
    margin-bottom: -20px;
    margin-top: -25px;
  }
.ProcessContent {
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  width: 80%;
  float: right;
  padding: 10px 20px 20px 20px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.ProcessContent h3 {
  font-size: 40px;
  color: #8e8e8e29;
  padding-left: 15px;
  margin: 0;
}
.ProcessContent h5 {
  color: #ff4805;
  width: 60%;
  text-align: center;
  padding-top: 15px;
}
.ProcessContent ul {
  margin: 0 0 0 20px;
  padding: 0;
}
.ProcessContent ul li {
  background-image: url("../../images/uiux-deliver/Icon\ material-check-circle.svg");
  padding: 5px 0 5px 30px;
  list-style: none;
  color: #7c7c7c;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px;
}
.uibg{
    background: linear-gradient(rgba(0,0,0, 0.2), rgba(0,0,0,0.5)),url("../../images/uiux-deliver/pexels-pixabay-273230.png") !important;
    background-position:center ;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.uibg p {
    font-size: 34px;
}
@media (max-width : 1150px){
    .ui-mob-image {
        display: none;
    }
}