.infobar-container {
    background-image: url("../../../images/background-btm.jpeg");
    background-color: #cccccc;
    min-height: 250px;
    opacity: 0.88;
    background-size: cover;
    background-repeat: no-repeat;

    .container-fluid {
        z-index: 1;
        font-size: 18px;
        margin-top: 5%;
        background-color: #428ADBDE;
        width: 100%;
        margin-left: 0px;
        min-height: 250px;
        color: white !important;
        padding-top: 70px;
        padding-bottom: 50px;

        .icon-container {
            position: relative;
            display: inline-block;
            height: 100px;
            width: 100px;
            box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
            padding: 30px 0;
            border-radius: 50%;
            background: #fff;
            text-align: center;
            margin-bottom: 1rem;
            margin-top:0.5rem;

            .icon {
                width: 50px;
                margin-top: 7px;            
            }
            
            .icon2 {
                width: 35px;
            }
        }

    }
}
