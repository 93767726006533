.adobehero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../images/adobe/carousel.png");
  width: 100%;
  float: left;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.adobe-analysis-img{
    min-width: 1100px;
}
.adobe-analysis-img img {
  width:450px;
  margin: 15em auto 2em auto;
  display: block;
  position: relative;

}
.adobe-analysis-img h1, .adobe-analysis-card-mobile h1{
color: #DDDDDD;
}
.adobe-analysis-card {
    position: absolute;
    top: 10px;
    height: auto;
    width: 230px;
    padding: 10px;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    left: 35em;
}
.adobe-analysis-card-mobile{
    height: auto;
    margin:10px auto;
    padding: 10px;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    background: #fff;
}
.adobe-analysis-card-2{
    left: 10em;
    top: 2em;
    height: auto;
    width: 230px;
    padding: 10px;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    position: absolute;

}
.adobe-analysis-card-2:after{
    height: 138px;
    background: url("../../images/xtime/bend-line.svg");

    right: -230px;
    content: "";
    position: absolute;
    width: 100%;
    top: 80px;
    background-repeat: no-repeat;
}

.adobe-analysis-card:after{
    height: 25px;
    background: url("../../images/xtime/line.svg");
    content: "";
    position: absolute;
    bottom: -130px;
    left: 30px;
    width: 100%;
    background-repeat: no-repeat;
    transform: rotate( 
90deg
 );
    z-index: -2;
}
.adobe-analysis-card-3{
    left: 0;
    top: 20em;
    height: auto;
    width: 230px;
    padding: 10px;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    position: absolute;
}

.adobe-analysis-card-3:after{
    width: 125px;
    background: url("../../images/xtime/line.svg");
    content: "";
    position: absolute;
    top: 90px;
    right: -125px;
    height: 25px;
    background-repeat: no-repeat;
    z-index: -2;
}
.adobe-analysis-card-4{
    left: 55em;
    top: 20em;
    height: auto;
    width: 230px;
    padding: 10px;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    position: absolute;
}

.adobe-analysis-card-4:after{
    width: 100px;
    background: url("../../images/xtime/line.svg");
    height: 25px;
    content: "";
    position: absolute;
    top: 90px;
    left: -100px;
    background-repeat: no-repeat;
    transform: rotate(180deg);
}
.adobe-analysis-card h4,.adobe-analysis-card-2 h4 ,.adobe-analysis-card-3 h4,.adobe-analysis-card-4 h4, .adobe-analysis-card-mobile h4{
  color: #2579d8;
  font-size: 18px;
  margin: 0;
  padding: 10px 0 0 0;
  width: 70%;

}
.adobe-analysis-card p, .adobe-analysis-card-2 p,.adobe-analysis-card-3 p,.adobe-analysis-card-4 p, .adobe-analysis-card-mobile p{
    font-size: 12px;
    color: #7c7c7c;
}

/* adobe campaign */

.adobecamp{
    display: flex;
     margin:20px auto ;
}
.adobecamp img{
    
    width: 50px;
    height: 50px;
    object-position: 50% 50%;
    margin:0 10px 0 30px;
}
.adobecamp span{
    padding: 12px;
    color: #7c7c7c;
}
.adobeprocessSec h1{
    color: #FF4805;
    text-align: right;
    padding:10px 0 0 0;
}
.adobeProcessCont{
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    color: #222;
    padding:15px 20px;
    margin:0;
    border-radius: 5px;
    position: relative;
    z-index: 100;
}
.processBorder:after{
    content:"";
    height: 20px;
    width:20px;
    background: #FF4805;
    border-radius: 50%;
    position: absolute;
    bottom: 0px;
    left:-10px;
}
.processBorder{
  
    height: 100px;
    border:1px dashed #000;
    margin:-25px auto 0 auto;
    width: 1px;
    position: relative;
}

.adobeProcessCont p{
    margin:0;
    padding:0;
    color: #7c7c7c;
}
.adobeprocessSec ul{
    margin:0;
    padding:0;
}
.adobeprocessSec ul li{
    list-style: none;
    display: flex;
    margin:-10px 0 25px 0;
}
.adobeprocessSec ul li:last-child{
    margin-bottom: 0;
}
.adobeprocessSec ul li img{
    width: 25px;
    max-height: 25px;
}
.adobeprocessSec ul li span{
    margin:0 10px;
    color: #7c7c7c;
}
/* newsletter section */
.newsletter-layout img{
    z-index: 1;
}
.newsletter-layout img:first-child{
    max-width: 280px;
    position: relative;
    transition: all 500ms 0s ease;
}
.newsletter-layout img:hover{
  z-index: 1;
  transform: scale(1.1);
}
.newsletter-layout img:nth-child(2){
    max-width: 300px;
    position: absolute;
    top:40px;
    left:200px;
    transition: all 500ms 0s ease;
}
.newsletter-layout img:nth-child(3){
    max-width: 300px;
    position: absolute;
    top:130px;
    left:220px;
    transition: all 500ms 0s ease;
}
.newsletter-layout img:nth-child(4){
    max-width: 300px;
    position: absolute;
    bottom:40px;
    left:250px;
    transition: all 500ms 0s ease;
}
.newsletter-info p:first-child{
    color: #7c7c7c;
    font-size: 16px;
    padding-left: 14.5em;
    padding-top: 10%;
    position: relative;
    top: 0em;
}
.newsletter-info p:nth-child(2){
    padding-top: 4.7em;
    color: #7c7c7c;
    font-size: 16px;
    padding-left: 14.5em;
    position: relative;
}
.newsletter-info p:nth-child(3){
    padding-top: 10.2em;
    color: #7c7c7c;
    font-size: 16px;
    padding-left: 8.2em;
    position: relative;
}
.newsletter-info p:nth-child(4){
    padding-top: 4.7em;
    color: #7c7c7c;
    font-size: 16px;
    padding-left: 17.5em;
    position: relative;
}

.newsletter-info p:after{
    content:"";
    background: url("../../images/adobe/line-fr.svg");
  height: 25px;
    width: 100%;
    top: 70%;
    position: absolute;
    left: -25%;
    background-repeat: no-repeat;

   
}
.newsletter-info p:nth-child(2):after{
    content:"";
    background: url("../../images/adobe/line-fr.svg");
    background-repeat: no-repeat;
    height: 25px;
    width: 100%;
    top: 5em;
    position: absolute;
    left: -25%;
}
.newsletter-info p:nth-child(3):after{
    content: "";
    background: url("../../images/adobe/line-fr.svg");
    background-repeat: no-repeat;
    height: 25px;

    width: 100%;
    top: 10.5em;
    position: absolute;
    left: -47%;
}
.newsletter-info p:nth-child(4):after{
    content:"";
    background: url("../../images/adobe/line-fr.svg");
    background-repeat: no-repeat;
    height: 25px;

    width:100%;
   top:5em;
    position: absolute;
    left:-15%;
}

/* adobe design */
.adobe-design{
    margin:2% auto 5% auto;
    max-width: 90%;
}
.adobe-design img:first-child{
    max-width: 340px;
    position: relative;
}
.adobe-design img:nth-child(2){
    max-width: 340px;
    position: absolute;
    top:12%;
    left:25%;
}
.adobe-design img:hover{
    z-index: 5;
    transform: scale(1.1);
}
.adobe-design img{
    transition: all 500ms 0s ease;
}
.adobe-design img:nth-child(3){
    position: absolute;
    top: -5%;
    width: auto;
    left: 35%;
}
.adobe-design img:nth-child(4){
    position: absolute;
    max-width: 500px;
    top: 20%;
    width: auto;
    left: 50%;

}
.adobe-design img:nth-child(5){
    position: absolute;
    top: 80%;
    width: auto;
    left: 50%;

}
/* founder */
.founder-img img{
    margin:30px auto;
    display: block;

}
.abt-founder p{
    color: #505050;
}
.abt-founder button{
border:1px solid #2579D8;
background: #fff;
padding:5px 15px;
margin:10px 0;
color: #2579D8;
transition: all 500ms 0s ease;
}
.abt-founder button:hover{
    background: #000;
    color: #fff;
}
.adobe-close-btn{
    margin: -20px auto 0px;
    display: block;
    
}
.newletter-layout-mobile img{
    width: 100%;
}
.newletter-layout-mobile p{
    font-size: 18px;
    padding:10px 0;
    text-align: center;
}
/* .adobe-list{
   list-style: none;
    margin:0 0 0 25px;
    padding: 0;
    list-style-position: outside;
    list-style: none;
}
.adobe-list li{
    color: #7c7c7c;
    font-size: 15px;
    margin-top: 20px;
    
   
}
.adobe-list li p::before{
    content: "\2022";
    color: red;
    font-weight: bold;
    display: initial; 
    width: 2em;
    margin-left: -20px;
    font-size: 26px;
    margin-right: 5px;
    line-height: 1.5;
}
.adobe-list li span{
    color: #2579d8;
    font-weight: bold;
} */
@media (max-width:768px){
    .adobecamp img{
        margin-left: 0;
    }
    .exp-img p{
        padding: 10px 0;
        font-size: 13px;
    }
}