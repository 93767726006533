.chero {
    width: 100%;
    height: auto;
    min-height: 400px;
    background: url("../../images/cloud/cloud/cloud-data-security.webp") center center no-repeat;
    background-size: cover;
    background-color: grey;
  }
.chero .ccontent {
    color: #fff;
    text-align: left;
    padding: 10% 200px;
    
}

.parallax-container-cloud{
  
  padding-bottom: 7%;
  padding-top: 7%; 
  
}
.parallax-container-cloud p{
    width: 80%;
    margin:0 auto;
    font-size: 28px;
    color: white;
    font-weight: 600;
    padding-top: 20px;
}

.parallax-container-cloud video{
  min-width: 100%;
  position: fixed;
  top:0;
  z-index: -999;
  
}
.cloudhero {
    width: 100%;
    float: left;
    min-height: 450px;
    background: url("https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/implementcloud.png") center center no-repeat;
}
.cloudhero .cloudcontent {
    color: #fff;
    text-align: left;
    padding: 162px 98px;
}
/* Gradient overlay with CSS */
.overlay:before{
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #242424;
    opacity: 0.3;
    z-index: 300;
  }
  /* added some backdrop-filter experiments */
  .overlay:after{
    backdrop-filter: 
      blur(5px)
      grayscale(.2)
      contrast(.8)
      sepia(.1)
      hue-rotate(180deg);
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .overlay {
    position:relative;
    display: block;
  }
  .overlay .mcontent {
      position: relative;
      z-index: 400;
  }
  /* END Overlay only code */
  .cloudhero {
    width: 100%;
    float: left;
    min-height: 450px;
    background: url("https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/implementcloud.png") center center no-repeat;
  }
.cloudhero .mcontent {
    color: #fff;
    text-align: left;
    padding: 162px 98px;
}

.integrationimg{
 padding-bottom: 40px;
  width: 72%;
}
.textright{
  text-align: right;
}
.animatedline-seven {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 300px;
  overflow: hidden;
  position: relative;
  
}
.animatedline-seven .line {
  height: 4px;
  background: #FF4805;
  position: absolute;
  top: 0px;
  /* left: 76%; */
  width: 90% !important;
  animation: dude .75s 0.5 forwards;
  font-weight: 0% !important;
}
.spanlistcloud{
  color:#7c7c7c;
  font-size: 14px;
  margin-left: 20px;
  white-space: nowrap;

}
.integration-img{
  background: url("https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/images/integrationbg.png");
  background-size: cover;
  padding-bottom: 55px;
  background-color: rgb(250, 250, 250);
}
.spancloudimg {
  width: 22px;
}
.cloud-p{
  font-size: 25px;
    color: white;
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
}

.animatedline-nine {
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    max-width: 280px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}
.animatedline-nine .line {
    height: 4px;
    background: #FF4805;
    position: absolute;
    top: 0px;
    left: 26%;
    width: 100% !important;


    -webkit-animation: dude .75s 0.5 forwards;
    -moz-animation: dude .75s 0.5 forwards;
    -o-animation: dude .75s 0.5 forwards;
    animation: dude .75s 0.5 forwards;
}
.expertimg {
  width: 35px;
}
.expertcard{
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    min-height: 850px;
    width: 90%;
    margin: auto;
    border-radius: 7px;
    text-align: justify;
    margin-top: 20px;
    padding-top: 32px;
    background: #fafafa;
    padding-bottom: 10px;
    margin-bottom: 40px;
}
.expertsubcard {
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  min-height: 150px;
  width: 90%;
  margin: auto;
  border-radius: 7px;
  padding: 30px 0px 10px 0px;
  text-align: justify;
  margin-top: 10px;
  transition: all 500ms 0s ease;
}
.expertsubcard:hover,.expertcardthird:hover{
    transform: scale(1.05);
}
.expertcardthird{
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    min-height: 150px;
    width: 90%;
    margin: auto;
    border-radius: 7px;
    padding: 30px 0px 10px 0px;
    text-align: justify;
    margin-top: 10px;
  margin-bottom: 28px;
  transition: all 500ms 0s ease;
}
.expertparagraph {
  text-align: center;
  color: #7c7c7c;
  font-size: 12px !important;
}

.animatedline-ten {

  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 200px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;

}
.animatedline-ten .line{
    height: 4px;
    background: #FF4805;
   margin:0 auto;
   display: block;
  
}
.cloudcaptionheading {
 
  color: white;
  font-weight: 600;
  text-align: left;
  padding-top:10%;
}
.cloudcaptionheadings {
  font-size: 27px;
  color: white;
  font-weight: 100;
  margin-top: 50px;
}
.integrationflex-container > div {
  width: 100%;
  margin: 10px;
  /* text-align: center; */
  /* line-height: 75px; */
  font-size: 17px;
}
.integrationflex-container {
  display: flex;
  flex-wrap: nowrap;
}
.animatedline-nine {

  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;

}


/* .storyhero {
  width: 100%;
  float: left;
  height: 262px;
  background: url("../../images/cloudbgimg.png") center center no-repeat;
}
.storyhero .storycontent {
  color: #fff;
  text-align: left;
  padding: 59px 98px;
} */


.storyhero {
  width: 100%;
    float: left;
    min-height: 403px;
    margin: auto;
 
  background: linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)), url("https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cloudstory.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.storyhero .cloudcontent {
  color: #fff;
  text-align: left;
  padding: 162px 98px;
}
/* .storyoverlay:before{
  content: '';
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #494545;
  opacity: 0.7;
  z-index: 300;
}
.storyoverlay:after{
  content: '';
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
} */
.cloudbgInfo p {
    font-size: 2.5vw;
  }
.storyoverlay {
  position:relative;
  display: block;
}
.storyoverlay .storycontent {
    position: relative;
    z-index: 400;
    margin-top: 31px;
}
.cloudstory1 {
 
  width: 100%;
  /* margin-top: 67px; */
  margin: 90px auto;
  /* margin-left: 72px; */
}
.cloudstorycard{
  z-index: 1;
  font-size: 18px;
  margin: 6% auto;
  background-color: #428ADBDE;
  width: 80%;
  min-height: 262px;
  color: white !important;
  transition: all 500ms 0s ease;
}
.cloudstorycard:hover{
    transform: scale(1.05);
}
@media (max-width:800px){
  .columntop {
    margin-top: 0px !important;
    margin-bottom: 30px !important;
  }
  .mhero .mmcontent{
    padding:50px 20px;
  }
  .chero .ccontent {
    padding: 20px 20px 20px 150px;
}
  .numberCircle{
    margin-top: 20px;
  }
}
@media screen and (max-width: 500px) {
  .mobileflex-container {
    display: flex;
    flex-wrap: nowrap;
    background-color: #f8f8f8;
    height: 80px;
    padding-top: 10px;
    overflow: auto;
    
}
.expertcard{
    min-height: auto;
}
.chero .ccontent {
    padding: 20px 0px 20px 75px;
}
.blacknav{
    white-space: space break-all;;
}
.mobileflex-container > div {
  width: 100%;
  margin: 5px;
  text-align: center;
  /* line-height: 75px; */
  font-size: 10px;
}
.cloudcaptionheadings {
  font-size: 20px;
  color: white;
  font-weight: 100;
  margin-top: 32px;
}

.hero .mcontent{
  padding: 50px 20px;
}
}


.mflex-container > div {
  width: 100%;
  margin: 10px 0 0 0;
  text-align: center;
  /* line-height: 75px; */
  font-size: 17px;

}


@media screen and (max-width: 450px) {
  .expertcard{
    width: 100%;
  }
  .expertcardthird{
    width: 95%;
  }
  .cloudstory1 {
    height: 227px;
    width: 100%;
    margin-top: 67px;
    /* margin: 0 auto; */
    margin-left: 0 auto;
}
.cloudstorycard{
  z-index: 1;
  font-size: 18px;
  margin-top: 6%;
  background-color: #428ADBDE;
  width: 62%;
  min-height: 262px;
  color: white !important;
}
}

.commonbtn {
  background-color: #FF4805;
  width: auto;
  border-radius: 40px;
  color: white;
  font-size: 10px;
  padding: 2px 5px;
  white-space: nowrap;
}

.storyflex-container > div {
  margin: 2px;
  font-size: 17px;
  margin-top: 4px;
  margin-bottom: 10px;
}
.storyflex-container {
  display: flex;
  flex-wrap: wrap;
}
.cloudparagraph{
  font-size: 17px;
}
.secondexpertcard{
  margin-top: 30px;
}

.ParallaxVideo{ 
  height: 300px;
  padding-bottom: 50px;
  padding-top: 50px; 
}
.ParallaxVideo video{ 
  min-width: 100%;
  position: fixed;
  top:0;
}
.ParallaxVideo h1 {
color: #fff;
font-size: 76px;
font-weight: 700;
text-align: center;
text-transform: uppercase;
}
/* 
@media (min-width:1600px) and (max-width:2000px){
    .blacknav{
        font-size: 20px ;
    }
    .devcard p{
        font-size: 20px !important;
    }
    .parallax-container-cloud p{
        font-size: 44px !important;
        padding:5% 0;
    }
   
    .portal-capable-content li{
        font-size: 24px !important;
    }
    .commonbtn{
        font-size: 14px;
        padding:5px 15px;
    }
    .cloudcaptionheading{
        font-size: 60px;
    }
    .cloudcaptionheadings{
        font-size: 45px;
    }
}
@media (min-width:2000px){
    .cloudcaptionheading{
        font-size: 80px;
    }
    .cloudcaptionheadings,.inih{
        font-size: 65px;
    }
    .devcard div img{
        padding-top: 15% !important;
    }
    .serveimg{
        width: 150px !important;
    }
    .blacknav{
        font-size: 22px;
    }
    .serveimg {
        width: 35%;
    }
    .devcard div {
        min-height: 330px;
    }
    .devparagraph,.inip{
        font-size: 30px;
        padding-bottom: 30px;
    }
    .parallax-container-cloud{
        padding-top:150px;
        padding-bottom: 150px;
    }
    .parallax-container-cloud p{
        font-size: 45px !important;
    }
    .portal-capable-content li{
        font-size: 28px !important;
        background-size: 36px !important;
    padding-left: 70px !important;
    }
    .expertimg {
        width: 65px;
    }
    .expertparagraph{
        font-size: 20px !important;
    }
    .expertcard{
        min-height: 1100px;
    }
    .cloudstorycard img{
        width: 30%;
    }
    .commonbtn{
        font-size: 22px;
    padding: 5px 15px;
    }
    .cloudparagraph,.storyflex-container{
        margin-bottom: 15px;
    }
} */




