html {
  scroll-behavior: smooth;
}
.mcarouselimg {
  width: 103%;
  margin-left: -21px;
}
.mcaption1 {
  position: absolute;
  right: 15%;
  bottom: 90px;
  left: 5%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.mcaptionheading {
  font-size: 62px;
  color: white;
  font-weight: 600;
  text-align: left;
}
.mcaptionheadings {
  font-size: 32px;
  color: white;
  font-weight: 100;
  margin-top: 50px;
}
.mimage-box {
  position: relative;
  margin: auto;
  overflow: hidden;
}
.mimage-box img {
  max-width: 98%;
  transition: all 0.3s;
  display: block;
  width: 98%;
  height: 343px;
  transform: scale(1);
  border-radius: 22px !important;
  border-bottom-right-radius: 27px !important;
  border-bottom-left-radius: 27px !important;
}

.mimage-box:hover img {
  transform: scale(1.1);
}
.mgridimg1 {
  width: 100%;

  transition: 0.3s;
  border-radius: 25px !important;
}
.mgridimg1:hover {
  width: 98%;
  /* height: 300px; */
  transform: scale(1.1);
}
.mgridimg2 {
  width: 98%;
  /* height: 300px; */
  transition: 0.3s;
  border-radius: 25px !important;
  /* border-bottom-right-radius: 31px !important;
    border-bottom-left-radius: 31px !important; */
  margin-top: 28px;
}
.mgridimg2:hover {
  width: 98%;
  /* height: 300px; */
  transform: scale(1.1);
}
.mparagraphheading {
  color: #7c7c7c;
  font-size: 15px;
  margin-top: 10px;
  line-height: 2.1;
}
.mparagraphheadingtop {
  color: #7c7c7c;
  font-size: 15px;
  margin-top: 20px;
  line-height: 2.1;
}

.mcontainerpaddingnew {
  padding-top: 40px;
  padding-bottom: 20px;
  margin: auto;
  overflow: hidden;
  background-color: rgb(250, 250, 250, 1);
}
.mcontainerpadding {
  padding-top: 40px;
  padding-bottom: 20px;
  margin: auto;
  overflow: hidden;
  background-color: #fff;
}
.devparagraph {
  color: #7c7c7c;
  font-size: 14px;
  line-height: 1.8;
}
.devcard {
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  width: 90%;
  margin: auto;
  border-radius: 22px;
  padding: 10px 30px 20px 30px;
  margin-bottom: 15%;
  transition: 0.3s;
  min-height: 380px;
}
.devcard div {
  min-height: 150px;
}

.devcard div img {
  margin: 0 auto;
  display: block;
  padding-top: 10%;
}
.devcard:hover {
  transform: scale(1.1);
}
.devheading {
  color: #7c7c7c;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}
.mbg-img {
  background: url("../../images/devbg.png");
  background-size: cover;
  padding-bottom: 40px;
  background-color: rgb(250, 250, 250);
  background-attachment: fixed;
}
.mfullimg {
  background: url("../../images/devimgfull.png");
  min-height: 450px;
  opacity: 0.88;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.mtext-over-img {
  z-index: 1;
  font-size: 18px;
  background-color: #242424;
  width: 100%;
  margin-left: -14px;
  padding: 44px;
  min-height: 450px;
  color: white !important;
  position: absolute;
  opacity: 0.5;
}
.mwhiteparagraph {
  font-size: 40px;
  color: white;
  font-weight: 600;
  -webkit-animation: fade 0.75s;
  -moz-animation: fade 0.75s;
  -o-animation: fade 0.75s;
  animation: fade 0.75s;
  transform: translateX(-100px);
  width: 80%;
  opacity: 1;
}

@-webkit-keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}
@-moz-keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}

@-o-keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}

/* Gradient overlay with CSS */
.overlay:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #242424;
  opacity: 0.3;
  z-index: 300;
}
/* added some backdrop-filter experiments */
.overlay:after {
  backdrop-filter: blur(5px) grayscale(0.2) contrast(0.8) sepia(0.1)
    hue-rotate(180deg);
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.overlay {
  position: relative;
  display: block;
}
.overlay .mcontent {
  position: relative;
  z-index: 400;
}
/* END Overlay only code */
.hero {
 background-size: cover !important;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../images/devimgfull.png") no-repeat !important;
}
.hero .mcontent {
  color: #fff;
  text-align: left;
  padding: 162px 98px;
}
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.numberCircle {
  background-color: #fff;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  position: relative;
  margin: auto;
  padding-top: 20px;
  transition: all 500ms 0s ease;
}
.numberCircle:hover {
  transform: scale(1.1);
}
.platimage {
  height: 44px;
  margin: 0 auto 0 auto;
  display: block;
}
.platcard {
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  width: 100%;
  margin: auto;
  border-radius: 22px;
  padding: 30px 15px 10px 15px;
  text-align: justify;
  margin-top: 50px;
  margin-bottom: 74px;
}

.platparagraph {
  text-align: center;
  color: #7c7c7c;
  font-size: 12px;
}
.platrowpadding {
  padding-top: 30px;
}

.devicep {
  color: #2579d8;
  font-size: 15px;
}
.iphoneimg {
  height: 68px;
  width: 35px;
}
.ipadimg {
  height: 50px;
  width: 72px;
  margin-top: 17px;
}
.deskimg {
  width: 64px;
  height: 36px;
  margin-top: 31px;
}
.desktopimg {
  width: 105px;
  height: 63px;
  margin-top: 12px;
}
.tabletimg {
  height: 40px;
  width: 65px;
  margin-top: 27px;
  margin-left: 8px;
}
.andtabletimg {
  height: 50px;
  width: 72px;
  margin-top: 17px;
  margin-left: 6px;
}
.tabletp {
  text-align: center;
  color: #7c7c7c;
  font-size: 12px;
  /* margin-left: 11px; */
}
.desktopp {
  /* width: 102px; */
  height: 54px;
  margin-top: 2px;
  /* margin-left: -17px; */
  color: #7c7c7c;
  font-size: 12px;
}
.ipadp {
  color: #7c7c7c;
  font-size: 12px;
}
.bluediv {
  height: auto;
  background-color: #7c7c7c;
  padding: 10px;
  width: 100%;
  /* margin: auto; */
  margin-top: 22px;
  cursor: pointer;
}
.bluediv.active,.bluedivnew.active {
  background-color: #2579d8;
  font-weight: bold;
}
.bluedivnew {
  height: auto;
  background-color: grey;
  padding: 10px;
  width: 120% !important;
  margin-left: 38px;
  cursor: pointer;
}
.bluep {
  font-size: 15px;
  color: white;
}
.blueh {
  color: #8e8e8e29;
  font-size: 43px;
}
.imagediv {
  background: #2579d8;
  min-height: 200px;
  position: relative;
  width: 228px;
  overflow: hidden;
  margin-top: 22px;
}

.steph {
  color: white;
  font-size: 20px;
  text-align: left !important;
  margin-top: 15px !important;
  
}
.stepp {
  color: white;
  font-size: 14px;
  text-align: left !important;
  margin-top: 10px !important;
  padding-bottom: 10px;
}
.rightcircleimg {
  width: 21px;
}

.initiancard {
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  min-height: 455px;
  width: 72%;
  margin: auto;
  border-radius: 22px;
  padding: 11px 15px 10px 15px;
  text-align: justify;
}
.inih {
  color: #8e8e8e29;
  font-size: 33px;
  margin-right: 10px;
}
.inip {
  color: #ff4805;
  font-size: 20px;
  text-align: left;
  padding-top: 10px;
  padding-left: 10px;
}
.inicontent {
  font-size: 14px;
  color: #7c7c7c;
  text-align: left;
  line-height: 1.8;
}
.airplaneimg {
  width: 80px;
}
.airplanediv {
  margin-top: 350%;
  margin-left: -20%;
}

.mflex-container {
  display: flex;
  flex-wrap: nowrap;
}

.mflex-container > div {
  width: 100%;
  margin: 10px;
  text-align: center;
  /* line-height: 75px; */
  font-size: 17px;
}
.mhero {
  width: 100%;
  float: left;
  min-height: 450px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../images/mobile/banner-mds.webp") center center no-repeat !important;
    background-size: cover !important;
}
.mhero .mmcontent {
  color: #fff;
  text-align: left;
  padding: 102px 98px;
}

/* Gradient overlay with CSS */
.moverlay:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #242424;
  opacity: 0.3;
  z-index: 300;
}
/* added some backdrop-filter experiments */
.moverlay:after {
  backdrop-filter: blur(5px) grayscale(0.2) contrast(0.8) sepia(0.1)
    hue-rotate(180deg);
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.moverlay {
  position: relative;
  display: block;
}
.moverlay .mmcontent {
  position: relative;
  z-index: 400;
}

.mnewhero {
  width: 91%;
  float: left;
  min-height: 336px;
  background: url("../../images/story1.png") center center no-repeat;
}
.mnewhero .mmcontent {
  color: #fff;
  text-align: left;
  padding: 102px 98px;
}

/* Gradient overlay with CSS */
.mnewoverlay:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  opacity: 0.7;
  z-index: 300;
}
/* added some backdrop-filter experiments */
.mnewoverlay:after {
  backdrop-filter: blur(5px) grayscale(0.2) contrast(0.8) sepia(0.1)
    hue-rotate(180deg);
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mnewoverlay {
  position: relative;
  display: block;
}
.mnewoverlay .mmcontent {
  position: relative;
  z-index: 400;
}
.successbox {
  z-index: 1;
  font-size: 18px;
  margin-top: -24%;
  background-color: #428adbde;
  width: 61%;
  margin-left: 39px;
  padding: 17px;
  min-height: 200px;
  color: white !important;
  /* padding-top: 90px; */
  position: relative;
  padding-top: 31px;
  transition: all 500ms 0s ease;
}

.iosbtn {
  background-color: #ff4805;
  height: 30px;
  width: 100px;
  border-radius: 40px;
  color: white;
  font-size: 13px;
}
.iosbtn:hover {
  background-color: #ff4805;
  height: 30px;
  width: 100px;
  border-radius: 40px;
  color: white;
  font-size: 13px;
}
.storyimg {
  height: 271px;
  width: 527px !important;
}
.storyimg1 {
  height: 271px;
  width: 22%;
  margin-left: 80%;
  margin-top: -58%;
}
.crossdiv {
  margin-top: 80px;
}
/* .mshero {
    width: 571;
    float: left;
    height: 271px;
    background: url("../../images/story1.png") center center no-repeat;
  }
.mshero .mmscontent {
    color: #fff;
    text-align: left;
    padding: 102px 98px;

}

.msoverlay:before{
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #242424;
    opacity: 0.3;
  }
  .msoverlay:after{
    backdrop-filter: 
      blur(5px)
      grayscale(.2)
      contrast(.8)
      sepia(.1)
      hue-rotate(180deg);
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .msoverlay {
    position:relative;
    display: block;
  }
  .msoverlay .mmcontent {
      position: relative;
      z-index: 400;
  }
  .successnew { 
    z-index: 3000;
    font-size: 18px;
    margin-top: -24%;
    background-color: #428ADBDE;
    width: 61%;
    margin-left: 39px;
    padding: 17px;
    min-height: 200px;
    color: white !important;
    position: relative;
    padding-top: 31px;
    transition: all 500ms 0s ease;
} 
.successnew1 { 
    z-index: 3000;
    font-size: 18px;
    margin-top: -24%;
    width: 61%;
    margin-left: 152px;
    padding: 17px;
    min-height: 200px;
    color: white !important;
    position: relative;
    padding-top: 31px;
    transition: all 500ms 0s ease;
}  */

.mobilebg-img {
  background: url("../../images/mobilebg.png");
  background-size: cover;
  padding-bottom: 7px;
}
.mobileflex-container {
  display: flex;
  flex-wrap: nowrap;
  background-color: #f8f8f8;
  height: 70px;
  padding-top: 12px;
  overflow: auto;
}

.mobileflex-container > div {
  width: 100%;
  margin: 10px;
  text-align: center;
  /* line-height: 75px; */
  font-size: 17px;
}
@media screen and (max-width: 450px) {
  .mcaptionheading {
    font-size: 25px;
    color: white;
    font-weight: 600;
    text-align: left;
  }
}

.animatedline-six {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 366px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.animatedline-six .line {
  height: 4px;
  background: #ff4805;
  position: absolute;
  top: 0px;
  /* left: 37%; */
  width: 100% !important;

  -webkit-animation: dude 0.75s 0.5 forwards;
  -moz-animation: dude 0.75s 0.5 forwards;
  -o-animation: dude 0.75s 0.5 forwards;
  animation: dude 0.75s 0.5 forwards;
}
.blacknav {
  color: #181a1c;
  font-size: 16px;
  white-space: nowrap;
}

.mobile-pro-card .initiancard {
  width: 100%;
  border-radius: 0;
  min-height: auto;
}

.mobile-pro-card .inicontent {
  margin-bottom: 25px;
}

@media (max-width: 480px) {
  .emaster-section .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .emaster-section .container .detail-section .detail {
    padding: 10px !important;
  }
  .emaster-section .container .detail-section {
    padding: 20px 20px 20px 5px !important;
  }
}
