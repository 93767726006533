.flow-card-container {
    .container {
        padding-top: 40px;
        margin: auto;
        overflow: hidden;

        .flow-card-box-row {
            [class^="col-"] {
                padding-right: 0;
                padding-left: 0;
            }


            @media screen and (min-width: 768px) {
                div:nth-of-type(2) {
                    margin-top: 5rem;
                }

                div:nth-of-type(3) {
                    margin-top: 10rem;
                }

                div:nth-of-type(4) {
                    margin-top: 15rem;
                }

               
            }

            @media (min-width:1024px){
                padding-right: 5rem;
                padding-left: 5rem;
    
            }

            .card {
                position: relative;
                margin: auto;
                overflow: hidden;
                border: none;


                .card-img-top {
                    max-width: 100%;
                    transition: all 0.3s;
                    display: block;
                    width: 100%;
                    height: auto;
                    transform: scale(1);

                    &:hover {
                        transform: scale(1.1);
                    }
                }

                .num-title {
                    font-size: 40px !important;
                    color: gray;
                    font-weight: 600;
                }

                .card-title {
                    font-size: 18px;
                    color: #2579d8;
                    font-weight: 600;
                }
                .card-text{
                    color: #7c7c7c;
                }
               .card-body .home-story{
                    font-size: 18px;
                }
            }
        }
    }
}

