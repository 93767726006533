.xtime-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../images/xtime/car-radio-listening-91028216.png");
}
.hero-styles {
  min-height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-styles .mwhiteparagraph {
  color: white;
  font-weight: 600;
  -webkit-animation: fade 0.75s;
  -moz-animation: fade 0.75s;
  -o-animation: fade 0.75s;
  animation: fade 0.75s;
  transform: translateX(-100px);
  width: 80%;
  opacity: 1;
  margin: 0 auto;
}
.hero-styles h1 {
  font-size: 36px;
  margin-top: 5%;
}
.hero-styles h4 {
  font-size: 24px;
  font-weight: normal;
  padding-top: 20px;
  line-height: 1.9;
}
/* about sec */
.xtimeAbout h2 {
  font-size: 26px;
}
.xtimeAbout p {
  font-size: 15px;
  line-height: 2.1;
  color: #7c7c7c;
}
.xtime-about-images {
  display: flex;
  margin: 10px 0;
  justify-content: space-around;
    align-items: center;
}
.xtime-about-images img {
  max-height: 45px;

  margin: 15px;
}
.xtime-about-images span {
  text-align: right;
  font-size: 15px;
  padding-left: 10px;
}
@-webkit-keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}
@-moz-keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}

@-o-keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}

.adobe-about-right img {
  width: 100%;
  margin-top: 20%;
}

/* xtime cloud */
.xtime-grey-bg {
  background: rgba(250, 250, 250, 1);
}
.xtimeCloud p {
  margin-top: 20px;
}
.adobe-about-left img {
  width: 100%;
}

.xtime-analysis-img img {
  max-width: 60%;
  float: right;
  margin: 20% 5% 21% 0;
  position: relative;
  z-index: 1;
  
}
.xtime-analysis-img .xtime-analysis-heading{
    display: flex;
}
.xtime-analysis-heading h4{
    font-size: 20px;
    color: #2579d8;
    width: 90%;
}
.xtime-analysis-heading h2{
   font-size: 40px;
   color: #c2c2c2;
   font-weight: normal;
   margin-top: -15px;
}
.xtime-analysis-img p{
    font-size: 14px;
    line-height: 1.8;
    color: #7c7c7c;
}
.analysis-cont:before {
  content: "";
  background: url("../../images/xtime/line.svg");
  min-height: 100px;
  min-width: 100%;
  position: absolute;
  background-repeat: no-repeat;
  transform: rotate(-90deg);
  left: 0;
  top: 65px;
  z-index: 0;
}
.analysis-cont-2:before{
    transform: rotate(90deg);
    top:-12px;
    left:-100px;
}
.analysis-cont-3:before{
    content: "";
  background: url("../../images/xtime/bend-line.svg");
  min-height: 100px;
  min-width: 100%;
  position: absolute;
  background-repeat: no-repeat;
 
  left: 100%;
  top: 30%;
  z-index: 0;
}
.analysis-cont-4:before{
    transform: scaleY(-1);
    left: 100%;
    top: -80%;
    height: 150px;
}


/* xtime products */
.xtime-mobile-products img{
    width: 100%;
}
.xtime-products img{
    display: block;
    margin:0 auto;
}
.xtime-products img:first-child{
    width: 30%;
}
.xtime-products img:nth-child(2){
    margin-top: -20px;
    width: 82%;
}
.xtime-product-dtl img{
   margin: 0 auto;
    display: block;
    max-width: 50%;
}
.xtime-product-dtl h5{
    font-size: 20px;
    text-align: center;
    padding:10px 0;
    color: #7c7c7c;
}
.xtime-product-dtl ul{
    margin:0 auto;
    padding:15px;
    background: #fff;
    display: block;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    text-align: center;

}
.xtime-product-dtl ul li{
    list-style: none;
    color: #2579d8;
    font-size: 15px;
    padding:5px 0 ;
}

/* xtime process section */
.xtime-process-sec h1{
    color: #ff4805;
    text-align: right;
    padding-top:15px;
    opacity: 1;

}
.xtime-process-cont{
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    padding:15px;
    max-width: 50%;
   
}
.xtime-process-cont p{
    color: #7c7c7c;
}
.process-line{
    transform: rotate(-90deg);
    height: 10px;
    margin: 5% 0 5% 20%;
    display: block;
}

.xtime-workflow img {
    width: 90%;
    margin: 0 auto;
    display: block;
}
.xtime-workflow {
    margin-bottom: 60px;
    position: relative;
    z-index: 1;

}
/* .xtime-workflow:first-child::after{
    content: "";
    background: url("../../images/xtime/line.svg");
    height: 20px;
    position: absolute;
    width: 50%;
    background-repeat: no-repeat;
    z-index: -2;
    top:45%;
    left:84%;
} */


/* xtime technology */
.xtime-technology{
    padding:50px 30px;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    width:90%;
    margin:10px auto 30px auto;
    text-align: center;
    border-radius: 20px;
}
.xtime-technology h4{
    font-size: 18px;
    color: #7c7c7c;
    font-weight: bold;
}
.xtime-tech-logo div div{
    min-height: 100px;
}
.xtime-tech-logo img{
    max-width: 80%;
    margin-top: 40px;
    max-height: 50px;
}

/* xtime cloud migration */
.cloud-mig-strategy ul{
    margin:0 0 0 20px;
    padding:0;
    list-style: none;
}
.cloud-mig-strategy ul li{
    margin:  20px 0;
  
}
.cloud-mig-strategy ul li p{
    line-height: 2.1;
}
.cloud-mig-strategy ul li p::before{
    content: "\2022";
    color: red;
    font-weight: bold;
    display: initial;
    width: 2em;
    margin-left: -20px;
    font-size: 26px;
    margin-right: 5px;
    line-height: 1.5;
}
.cloud-mig-strategy img{
    width: 100%;
}
.xtime-final-images{
    position: relative;
   margin-left: 5%;
}
.xtime-final-images img{
    max-width: 700px;
    display: block;
    transition: all 500ms 0s ease;
    position: relative;
  
}
.xtime-final-images img:hover{
    transform: scale(1.1);
    z-index: 10;
}

.xtime-final-images img:nth-child(3){
    position: absolute;
    top:8%;
    left:40%;
  
}
.xtime-final-images img:nth-child(4){
    position: absolute;
    top:35%;
    left:50%;
   
}

.xtime-productlist-mobile h4{
    color:#2579d8 !important;
    font-weight: bold;
    font-size: 15px;
    text-transform: capitalize;
}
.xtime-productlist-mobile{
    margin-top: 15px;
}

.xtime-final-img-mobile img{
   max-width: 80%;
   width: 100%;
    display: block;
    margin:15px auto;
}
.xtime-final-img-mobile{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  
}

/* xtime nav */
.xtime-nav{
    background: #f8f8f8;
}
@media (min-width:1400px){
    .xtime-nav .mobileflex-container{
        max-width: 1350px;
        margin:0 auto;
    }
}

@media (max-width:992px){
    .hero-styles .mwhiteparagraph{
        transform: translateX(0);
        width: 100%;
        animation:none;
    }
    .hero-styles h1{
        font-size: 6vw;
        margin-top: 0;
    }
    .hero-styles h4{
        font-size: 22px;
        padding-top: 0;
        line-height: 1.9;
    }
    .hero-styles{
        min-height: 230px;
    }
}

@media (max-width:500px){
    .process-line{
        margin: 12% 0 12% 20% !important;
    }
    .xtime-img{
        transform: translate(120px);
    }
}

@media (max-width:767px){
    .digicard{
        min-height: 50px;
    }
    .cloudcaptionheading{
        padding-top: 20%;
    }
    .xtime-mobile-products img{
        width: 95%;
    }
    .xtime-product-dtl img{
        max-width: 80%;
    }
    .xtime-product-dtl h5{
        font-size: 12px;
    }
    .xtime-process-cont{
        max-width: 100%;
    }
    .xtimelist{
        margin-top:-60px;
    }
    .xtime-mobile img{
        max-width: 60%;

    }
};


