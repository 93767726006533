.digihero{
    width: 100%;
    background:linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)),  url('../../images/digitaltrans/digitaltrans/dt-banner1.webp') center center no-repeat;
}
.digihero .ccontent{
padding-top:10%;
}
.digicard{
    width: 95%;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    min-height: 350px;
   
    margin: auto;
    border-radius: 22px;
    padding: 11px 30px 10px 30px;
    margin-bottom: 40px;
    transition: .3s;

}
.digicard:hover{
    transform: scale(1.05);
}
.digibg{
    background: linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)),url('../../images/digitaltrans/digitaltrans/digi-bg.jpeg') !important;
    background-size: cover !important;
    background-repeat: no-repeat;
}
.digibg .cloud-p{
    font-size: 40px;
    width: 100%;
    padding:0 50px;
}
.digibg .mcontent{
    padding: 80px 50px;
}
.digi-circle{
    background-color: #fff;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    position: relative;
    margin: auto;
    padding-top: 20px;
}
.digi-circle img{
    width: 60%;
    margin:0 auto;
    display: block;
}
.digi-block{
    background-color: #FCFCFC;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    border-radius: 7px;
    text-align: justify;
    margin-top: 50px;
    padding-bottom: 46px;
    padding-top: 32px;
}
.digi-expert{
    color: #7c7c7c;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.digi-sm-image div{
    max-height: 90px;
}
.mflex-container img{
    max-height: 50px;
    margin:0 auto;
    display: block;
    
  }

.digi-platforms p.platparagraph{
    padding: 15px;
}
.digi-platforms{
    min-height: inherit;
    background-color: #FCFCFC;
   
}
.digicircle{
    width: 65%;
    margin:15% auto;
    display: block;
}


/* circle */
.icon {
    position: relative;
    background: white;
    cursor: pointer;
    margin: auto;
    color: deepskyblue;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    line-height: 150px;
    text-align: center;
    font-size: 2.75em;
    font-weight: bold;
    transition: 0.24s 0.2s;
  }
  
  .icon  {
    background: rgba(255, 255, 255, 0.75);
  }
  
  .menu {
    position: absolute;
    top: -75px;
    left: -75px;
    border: 150px solid transparent;
    cursor: default;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 1.4s 0.07s;
    z-index: -5;
  }
  
  .spread {
    position: absolute;
    top: -100px;
    left: -100px;
    transform-origin: 100px 100px;
    transition: all 0.5s 0.1s;
  }
  
  .icon .spread:nth-child(9) .unit:after{
      border:1px solid #ccc;
      width:20px;
  }
  .icon  .menu {
    transition: transform 0.4s 0.08s, z-index   0s  0.5s;
    transform: scale(1);
    z-index: 5;
  }
  .icon  .spread, .icon  .unit {
    transition: all 0.6s;
  }
  .icon  .spread:nth-child(1) {
    transition-delay: 0.02s;
    transform: rotate(85deg);
  }
  .icon  .spread:nth-child(1) .unit {
    transition-delay: 0.04s;
    transform: rotate(635deg);
  }
  .icon  .spread:nth-child(2) {
    transition-delay: 0.04s;
    transform: rotate(125deg);
  }
  .icon  .spread:nth-child(2) .unit {
    transition-delay: 0.08s;
    transform: rotate(595deg);
  }
  .icon  .spread:nth-child(3) {
    transition-delay: 0.06s;
    transform: rotate(165deg);
  }
  .icon  .spread:nth-child(3) .unit {
    transition-delay: 0.12s;
    transform: rotate(555deg);
  }
  .icon  .spread:nth-child(4) {
    transition-delay: 0.08s;
    transform: rotate(205deg);
  }
  .icon  .spread:nth-child(4) .unit {
    transition-delay: 0.16s;
    transform: rotate(515deg);
  }
  .icon  .spread:nth-child(5) {
    transition-delay: 0.1s;
    transform: rotate(245deg);
  }
  .icon  .spread:nth-child(5) .unit {
    transition-delay: 0.2s;
    transform: rotate(475deg);
  }
  .icon  .spread:nth-child(6) {
    transition-delay: 0.12s;
    transform: rotate(285deg);
  }
  .icon  .spread:nth-child(6) .unit {
    transition-delay: 0.24s;
    transform: rotate(435deg);
  }
  .icon  .spread:nth-child(7) {
    transition-delay: 0.14s;
    transform: rotate(325deg);
  }
  .icon  .spread:nth-child(7) .unit {
    transition-delay: 0.28s;
    transform: rotate(395deg);
  }
  .icon  .spread:nth-child(8) {
    transition-delay: 0.16s;
    transform: rotate(365deg);
  }
  .icon  .spread:nth-child(8) .unit {
    transition-delay: 0.32s;
    transform: rotate(355deg);
  }
  .icon  .spread:nth-child(9) {
    transition-delay: 0.18s;
    transform: rotate(405deg);
  }
  .icon  .spread:nth-child(9) .unit {
    transition-delay: 0.36s;
    transform: rotate(315deg);
  }
  
  /* END .icon  */
  .unit {
    position: absolute;
    background: white;
    font-size: 60%;
    text-decoration: none;
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: dodgerblue;
    border-radius: 50%;
    transition: 0.6s;
  }
  
  
  .icon {
    top: 50%;
    margin-top: -75px;
  }
  
  @media (max-width:1120px){
    .digibg{
        background-size: cover;
    }
  }
  @media (max-width:700px){
    .digibg .cloud-p{
        padding:0;
        font-size: 24px;
    }
    .emaster-section .container{
        padding-right: 1rem;
        padding-left: 1rem;
    }
  }
  @media (max-width:600px){
  
    .cloudcaptionheading{
        font-size: 35px;
    }
  }