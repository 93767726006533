$color-white:#fff;
$brand-color-g:#E5D3D3;
$brand-color:#df8625;

@mixin respond-to($breakpoint) {
    @if $breakpoint == "xs" {
        @media screen and (max-width: 767px) {
          @content;
        }
    }
    @else if $breakpoint == "sm" {
        @media (min-width: 768px) and (max-width: 991px) {
            @content;
        }
    }
    @else if $breakpoint == "md" {
        @media (min-width: 992px) and (max-width: 1199px) {
            @content;
        }
    }
    @else if $breakpoint == "lg" {
        @media screen and (min-width: 1200px) {
            @content;
        }
    }
}

.ps-timeline-sec {
    position: relative;
   
    .container {
        position: relative;

        ol {

            @include respond-to(xs) {
                &:before {
                    background: $brand-color-g;
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    position: absolute;
                    top: 130px !important;
                    left: 36px !important;
                }

                &:after {
                    background: #E5D3D3;
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    position: absolute;
                    top: inherit !important;
                    // left: 36px;
                }

                &.ps-timeline {
                    margin: 130px 0 !important;
                    border-left: 2px solid #E5D3D3;
                    padding-left: 0 !important;
                    padding-top: 120px !important;
                    border-top: 0 !important;
                    margin-left: 25px !important;

                    li {
                        height: 220px;
                        float: none !important;
                        width: inherit !important;

                        &:nth-child(2) {
                            .img-handler-bot {
                                img {
                                    width: 70px;
                                }
                            }
                        }

                        &:last-child {
                            margin: 0;
                            bottom: 0 !important;
                            height: 120px;

                            .img-handler-bot {
                                bottom: 40px !important;
                                width: 40% !important;
                                margin-left: 25px !important;
                                margin-top: 0 !important;

                                img {
                                    width: 100%;
                                }
                            }

                            .ps-top {
                                margin-bottom: 0 !important;
                                top: 20px;
                                width: 50% !important;
                            }
                        }



                        span {
                            left: 0 !important;

                            &.ps-sp-top {
                                &:before {
                                    content: none !important;
                                }

                                &:after {
                                    content: none !important;
                                }
                            }

                            &.ps-sp-bot {
                                &:before {
                                    content: none !important;
                                }

                                &:after {
                                    content: none !important;
                                }
                            }
                        }

                        .img-handler-top {
                            position: absolute !important;
                            bottom: 150px !important;
                            width: 30% !important;
                            float: left !important;
                            margin-left: 35px !important;
                            margin-bottom: 0 !important;

                            img {
                                margin: 0 auto !important;
                                width: 80% !important;
                            }
                        }

                        .img-handler-bot {
                            position: absolute !important;
                            bottom: 115px !important;
                            width: 30% !important;
                            float: left !important;
                            margin-left: 35px !important;
                            margin-bottom: 0 !important;

                            img {
                            }
                        }

                        p {
                            text-align: left !important;
                            width: 100% !important;
                            margin: 0 auto !important;
                            margin-top: 0px !important;
                        }

                        .ps-top {
                            width: 60% !important;
                            float: right !important;
                            right: 0;
                            top: -40px;
                        }

                        .ps-bot {
                            width: 60% !important;
                            float: right !important;
                            right: 0;
                            top: -40px;
                        }
                    }
                }
            }


            &:before {
                background: $brand-color-g;
                // content: "";
                width: 10px;
                height: 10px;
                border-radius: 100%;
                position: absolute;
                left: 8px;
                top: 49.5%;
            }

            &:after {
                background: $brand-color-g;
                // content: "";
                width: 10px;
                height: 10px;
                border-radius: 100%;
                position: absolute;
                right: 8px;
                top: 49.5%;
            }

            &.ps-timeline {
                margin: 390px 0;
                padding: 0;
                // border-top: 2px solid $brand-color-g;
                list-style: none;
                // width: 93%;

                li {
                    float: left;
                    width: 20%;
                    padding-top: 30px;
                    position: relative;
                    border-top:2px solid #E5D3D3;

                   
                    span {
                        width: 30px;
                        height: 30px;
                       
                        background: #FF4805;
                        border: 4px solid #E5D3D3;
                        border-radius: 50%;
                        box-shadow:0 10px 20px rgb(80 80 80 / 10%);
                        -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
                        -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
                        text-align: center;
                        line-height: 50px -10;
                        color: #df8625;
                        font-size: 2em;
                        font-style: normal;
                        position: absolute;
                        top: -14px;
                        left: 0;

                        &.ps-sp-top {
                            &:before {
                                content: '';
                                color: #E5D3D3;
                                width: 2px;
                                height: 71px;
                                background: #E5D3D3;
                                position: absolute;
                                top: -73px;
                                left: 50%;
                            }

                            &:after {
                                // content: '';
                                color: $brand-color-g;
                                width: 8px;
                                height: 8px;
                                background: $brand-color-g;
                                position: absolute;
                                bottom: 90px;
                                left: 44%;
                                border-radius: 100%;
                            }
                        }

                        &.ps-sp-bot {
                            &:before {
                                content: '';
                                color: #E5D3D3;
                                width: 2px;
                                height: 67px;
                                background: #E5D3D3;
                                position: absolute;
                                bottom: -71px;
                                left: 50%;
                            }

                            &:after {
                                // content: '';
                                color: $brand-color-g;
                                width: 8px;
                                height: 8px;
                                background: $brand-color-g;
                                position: absolute;
                                top: 90px;
                                left: 44%;
                                border-radius: 100%;
                            }
                        }
                    }

                    .img-handler-top {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: 113px;
                        width: 100%;
                        left:-70%;

                        img {
                            display: table;
                            margin: 0 auto;
                        }
                    }

                    .img-handler-bot {
                        position: absolute;
                        margin-top: 31px;
                        min-width: 100%;
                        margin-left: -30%;
                       

                        img {
                            display: table;
                            margin: 0 auto;
                        }
                    }

                    p {
                        text-align: center;
                        width: 80%;
                        margin: 0 auto;
                    }

                    .ps-top {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: 43px;
                        margin-left: -30px;
                    }

                    .ps-bot {
                        position: absolute;
                        margin-top: -16px;
                        
                    }
                }
            }
        }
    }
}