.portalhero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../images/portal/business-people-collaboration-team-teamwork-peofessional-concept-47348249.png");
  width: 100%;
  float: left;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.portalhero .mwhiteparagraph {
  -webkit-animation: fade 0.75s;
  -moz-animation: fade 0.75s;
  -o-animation: fade 0.75s;
  animation: fade 0.75s;
  transform: translateX(-100px);
  opacity: 1;
}
.fade-image-right {
  -webkit-animation: fade 0.75s;
  -moz-animation: fade 0.75s;
  -o-animation: fade 0.75s;
  animation: fade 0.75s;
  transform: translateX(-100px);
  opacity: 1;
}
@-webkit-keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}
@-moz-keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}

@-o-keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  100% {
    transform: translateX(-100px);
    opacity: 1;
  }
}

.portalhero .cloudcaptionheadings {
  font-size: 24px;
  color: white;
  font-weight: 100;
  margin-top: 30px;
  margin-bottom: 120px;
}

.fade-right {
  transform: translateX(-100px);
}
.exp-img div {
  height: 50px;
}
.exp-img div img {
  height: auto;
  max-height: 50px;
}
.portalbg {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),url("../../images/portal/business-people-handshake-after-partnership-contract-signing-94512900.png") !important;
  padding: 10%;
    color: #fff;
    background-size: cover !important;
}
.portal-story {
  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("../../images/portal/Screenshot\ 2021-04-19\ at\ 12.27.00\ AM.png");
  background-size: 100%;
  height: auto;
  background-repeat: no-repeat;
  margin: 0 10px 10px 10px;
  transition: transform 500ms 0s ease;
}
.portal-story:hover, .portal-story-b:hover{
    transform: scale(1.1,1.1);

}
.portal-story-b {
  background: url("../../images/portal/Screenshot\ 2021-04-19\ at\ 12.27.09\ AM.png");
  background-size: 100%;
  height: auto;
  background-repeat: no-repeat;
  margin: 0 10px 10px 10px;
  transition: transform 500ms 0s ease;
  min-height: 293px;
}
.portal-story-block-a {
  background: #428adbdd;
  width: 80%;
  height: auto;
  margin: 7em 30px 0 30px;
  padding: 20px 30px 20px 20px;
  color: #fff;
}
.portal-story-block-a ul,
.portal-story-block-c ul {
  margin: 0;
  padding: 0;
  display: table;
}
.portal-story-block-a ul li,
.portal-story-block-c ul li {
  display: inline-block;
  background: #ff4805;
  border-radius: 30px;
  font-size: 14px;
  padding: 2px 20px;
  margin: 10px 5px 20px 0;
}
.portal-story-block-b img {
  width: 55%;
  margin-top: 20px;
}
.portal-story-block-b {
  width: 60%;
}
.portal-story-block-c {
  background: #428adbdd;
  width: 45%;
  height: auto;
  margin: 70px 30px 0 0;
  padding: 20px 30px 20px 20px;
  color: #fff;
}
.portal-service-img {
  height: 120px;
}
.portal-service-img img {
  margin: 30px auto !important;
  display: block;
  max-height: 70px;
}
.portalbg .mcontent {
  color: #fff;
  text-align: left;
  padding: 100px 50px;
}
.portal-capable-content {
  width: 0 !important;
  transition: all 2000ms 0s ease;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.portal-capable-content li {
  font-size: 14px;
  background-image: url("../../images/bluearrow.png");
  white-space: nowrap;
  background-repeat: no-repeat;
  list-style: none;
  color: #7c7c7c;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px;
  padding-left: 30px;
  margin-bottom: 20px;
  text-align: left;
  line-height: 1.9;
}

.animate-text .portal-capable-content {
  width: 100% !important;
}
/* .portalcontent{
    max-width: 70%;
} */
.portal-exp-icon{
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
border: 1px solid #C9C9C9;
height: 110px;
width: 110px;
border-radius: 50%;
margin:0 auto 10px auto;
display: block;
padding-top: 20px;
transition: all 500ms 0s ease;
}
.portal-exp-icon:hover{
  transform: scale(1.2,1.2);
}
.portal-exp-icon img{
    width: 60%;
    margin:0 auto;
    display: block;

}

.timeline {
    border-left: 3px solid #E5D3D3;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: #fff;
    margin: 0px auto 0 auto;
    letter-spacing: 0.2px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 0 50px;
    list-style: none;
    text-align: left;
    max-width: 40%;
}
.rtl .timeline {
    border-left: 0;
    text-align: right;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-right: 3px solid #727cf5;
}

.rtl .timeline .event::before {
    left: 0;
    right: -170px;
}
.bluediv-mobile{
    height: auto;
    background-color: #2579D8;
    padding: 10px;
    width: 100%;
    
    margin-top: 22px;
    cursor: pointer;
    
}
.bluediv-mobile p{
   padding: 10px 0 0 0;
}
.bluediv-mobile::before{
    content: "";
    height: 2px;
    width: 100px;
    background: #E5D3D3;
}
.imagedivmobile .steptext-over-img{
    
        padding:10px;
    }


@media (max-width: 767px) {
    .timeline {
        max-width: 98%;
        padding: 0 25px 0 15px;
    }
    .portal-story-block-c{
        width: 80%;
       
    }
    .portal-story-b{
        min-height: auto;
    }
}

.timeline h1 {
    font-weight: 300;
    font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
}

.timeline .event {
    border-bottom: 1px dashed #e8ebf1;
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative;
}

@media (max-width: 767px) {
    .timeline .event {
        padding-top: 30px;
    }
}

.timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.timeline .event:before,
.timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .event:before {
    left: -207px;
    content: attr(data-date);
    text-align: right;
    color: #8E8E8E29;
    font-size: 43px;
    min-width: 120px;
}

@media (max-width: 767px) {
    .timeline .event:before {
        left: 0px;
        text-align: left;
    }
}

.timeline .event:after {
    width: 30px;
    height: 30px;
    background: #FF4805;
    border: 4px solid #E5D3D3;
    border-radius: 50%;
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    left: -67px;
    border-radius: 50%;
    content: "";
    top: 0px;
}
.initiancard-mobile{
    width: 100%;
    margin-top: 10px;
    padding:10px 10px 10px 10px;
    text-align: left;
}
.initiancard-mobile ul{
    margin: 0;
    padding: 0;
}
.initiancard-mobile ul li {
background-image: url("../../images/rightcircle.png");
background-repeat: no-repeat;
list-style: none;

    list-style: none;
    color: #7c7c7c;
    background-position: left center;
    background-size: 15px;
    padding-left: 30px;
    margin-bottom: 20px;
}
.initiancard-mobile{
    height: auto;
    min-height: initial;
}

@media (max-width: 767px) {
    .timeline .event:after {
        left: -31.8px;
    }
}

.rtl .timeline {
    border-left: 0;
    text-align: right;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-right: 3px solid #727cf5;
}

.rtl .timeline .event::before {
    left: 0;
    right: -170px;
}

.rtl .timeline .event::after {
    left: 0;
    right: -55.8px;
}
@media (max-width:500px){
    .portalbg .mcontent{
        padding:20px;
    }
    .portalbg .mcontent .cloud-p{
        width: 100%;
    }
    .portal-story-block-a,.portal-story-block-c{
        padding:10px;
    }
    .portal-story-block-a ul li, .portal-story-block-c ul li{
        padding:2px 10px;
    }
    .portal-story-block-b{
        width:0;
    }
}
@media (max-width:768px){
    .portal-cap-p{
        padding:20px 0;
     
    }
    .portal-cap-p img{
        margin-bottom: 50px;
    }
}