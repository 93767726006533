.webhero{
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/webdevelopment/closeup-coding-on-screen-hands-coding-html-and-programming-on-screen-laptop-web-development-developer-114334729.png");
    width: 100%;
    float: left;
    
    background-size: cover;
    background-repeat: no-repeat;
}
.webbg{
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/webdevelopment/protection-and-storage-of-digital-data-using-the-blockchain-technology-artificial-intelligence-based-on-neural-networks-global-i-134373137.png") !important;
    padding: 10%;
    color: #fff;
    background-size: 100% !important;
}
.webmaster .container-fluid .detail-section .image-detail img{
    width: 100%;
    margin-top: 25%;
}
.webmaster-img img{
    width: 100% !important;
    padding-top: 25%;
}