.about_hero{
  
    height: 30vw;
   
}
.about_hero img{
    width: 100%;
}

.img-about img{
    max-height: 250px !important;
}
.about-vision{
    background: url("../../images/about/millennial-black-businesswoman-addressing-colleagues-at-a-corporate-business-meeting-close-up-153629913.png");
    padding:40px 0;
    background-size: cover;
    background-position: center;

}
.vision-box{
    background: #428ADBDD;
    color: #fff;
    padding:40px;
    margin-top: 50%;
    
}
.vision-box-2{
    margin-top: 70%;
    margin-left: 5%;
}
@media (max-width:768px){
    .vision-box{
        margin-top: 10%;
        padding:10px;
        
    }
    .vision-box-2{
        margin-top: 5%;
        padding:10px;
        margin-left: 0;
    }
    .vision-box h3{
        text-align: center;

    }
   
}
.ind-cr{
    max-height: 100px !important;
    max-width: 100px !important;
}
.ind-cr img{
    width: 100%;
    display: block;
    margin:0 auto;
}
.about-clients{
    display: flex;
    margin:40px 0;
    width: 100%;
  padding:0;
  justify-content: space-around;
  align-items: baseline;
}
.about-clients li{
    list-style: none;
   
}
.about-clients li div{
    display: block;
    margin:0 auto;
    max-height: 100px;
    
}
.about-clients li div img{
    max-width: 15vw;
    max-height: 100px;
}

.about-team img{
    width: 90%;
    float: right;
    position: relative;
}
.about-team .team-dtl{
    position: absolute;
    background: #428ADBDD;
    bottom: 20px;
    color: #fff;
    padding:10px 25px;
}
.team-dtl p{
    color: #fff;
}