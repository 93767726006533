body {
  font-family: "Nunito Sans", sans-serif;
  overflow-x: hidden !important;
}

#root {
  overflow-x: hidden;
}
a {
  text-decoration: none !important;
}

.navbar {
  background-color: #fff;
  padding-top: 16px;
  border-radius: 0px;
}
p {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1.8;
  font-family: "Nunito Sans", sans-serif;
}
li {
  font-family: "Nunito Sans", sans-serif;
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1240px !important;
  }
}

.navbar .navbar-nav .nav-link {
  font-size: 18px;
  color: #2579d8;
  transition: all 500ms 0s ease;
  text-align: center;
}

.navbar-toggler{
    padding:0;
    border:none;
}

.dropdown-menu a {
  font-size: 14px;
}

.navbar .navbar-nav .dropdown-menu {
  left: 10px;
  columns: 2;
  text-align: left;
}
.navbar .navbar-nav .main-nav .nav-link {
  text-align: left;
  padding: 5px 0 !important;
  margin-left: 0 !important;
  font-size: 16px;
}

.navbar .navbar-nav .main-nav:hover {
  background-color: #f8f8f8 !important;
}
.navbar .navbar-nav .main-nav:hover .nav-link {
  color: #000 !important;
}

.navbar .navbar-nav .nav-link.active {
  color: #000 !important;
  background-color: #ebeff3;
}
.dropdown-item:active {
  color: #fff !important;
  background-color: #ebeff3 !important;
}
.dropdown-item:focus {
  color: #fff !important;
}
.aboutimg1:hover{
    transform: none !important;
    width: 100% !important;
}
.home-page-list {
  margin: 0;
  padding: 0;
}
.homelist , .adobe-list{
  list-style-position: outside;
  list-style: none;
  margin: 20px 0 10px 20px;
  padding: 0;
}
.homelist li , .adobe-list li {
  font-size: 15px;
  font-weight: 600;
  color: #2579d8;
  list-style: none;
  position: relative;
  padding-bottom: 14px;
  margin: -6px 0 0 0;
}
.homelist li::after, .adobe-list li::after {
  content: url("../images/icons/dot.png");
  position: absolute;
  top: -2px;
  left: -21px;
}
.homelist li:before , .adobe-list li::before{
  /* lines */
  content: "";
  position: absolute;
  left: -18px;
  border-left: 2px solid #dddddd;
  height: 100%;
  width: 1px;
}
.homelist li:first-child:before , .adobe-list li:first-child::before {
  top: 6px;
}
.homelist li:last-child:before, .adobe-list li:last-child::before {
  height: 6px;
}
.homelist li p , .adobe-list li p {
  font-family: 600;
}
.navbar-brand > img {
  display: block;
  width: 63px;
}

.navbar .navbar-nav .nav-link {
  padding: 5px 20px;
  margin: 0 10px;
}

.navbar .navbar-nav .nav-link:focus,
.navbar .navbar-nav .nav-link:hover {
  color: #2579d8;
}

.industries-carousel .slick-track,
.industries-carousel .slick-list {
  min-height: 250px;
}

#capability-list .animate-text {
  margin: 0 !important;
}
.clients-carousel{
    max-width: 90% ;
    margin:0 auto;
    
}
.clients-carousel div img{
    width: 90%;
  
}
@media (min-width: 992px) {
  .navbar .navbar-nav {
    padding-top: 10px;
  }
  
}
@media (max-width: 992px) {
  .home-story-box {
    min-height: 300px;
  }
  .navbar-collapse .navbar-nav .nav-link{
    text-align: left !important;
    padding:10px 0 !important;
}
  .header-mobile-btn {
    display: block !important;
  }
  .navbar-brand {
    margin: 0 auto !important;
  }
  .navigation-sidebar {
    text-align: right;
  }
  .navigation-sidebar .navbar-nav .nav-link {
    text-align: right;
    white-space: break-spaces;
  }
  .navigation-sidebar .navbar-nav .nav-link svg {
    position: absolute;
    right: 10px;
    font-weight: bold;
    margin-top: 8px;
    color: #7c7c7c;
    height: 12px;
    width: 12px;
  }
  #dropdown-navigation .nav-link {
    color: #7c7c7c !important;
    font-size: 15px !important;
  }
  .navigation-sidebar .navbar-nav .dropdown-menu {
    columns: initial;
    border: none;
  }
  .navigation-sidebar .navbar-nav .dropdown-menu .nav-link {
    color: #7c7c7c !important;
    padding-right: 0;
    font-size: 16px;
    padding-left: 0;
  }
  .navigation-sidebar button {
    background: none;
    border: none;
    font-size: 26px;
    padding: 20px;
    color: grey;
  }
  .navigation-sidebar .dropdown-toggle::after {
    margin-right: -0.8em;
  }
  .togglesearch, .searchresult{
      right: 20px !important;
  }
}

@media (min-width: 200px) and (max-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 98%;
  }
}

@media (max-width: 500px) {
  .h2heading1 {
    white-space: pre-wrap !important;
    width: 100% !important;
    font-size: 18px !important;
  }
  .portal-capable-content{
      margin-top:30px !important;
  }
  .industries-carousel .slick-track,
  .industries-carousel .slick-list {
    min-height: 220px;
  }
  .subheader {
    font-size: 16px !important;
    line-height: 1.9;
  }
  .paragraphheading,
  p {
    font-size: 15px !important;
  }
  .story-box-container .alice-carousel__wrapper {
    min-height: 400px !important;
  }
  .flow-card-container .container .flow-card-box-row {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .mobile-space-inc{
      margin-top: 20px !important;
  }
  .mobile-space-dec{
      padding-bottom: 0 !important;
  }
  .adjust-position{
      margin-left: 15px;
  }
  .adjust-position-img{
      margin-left: -15px;
  }
  
}

@media (min-width: 2000px) {
  .expheading {
    font-size: 50px !important;
  }
  .btn-floating {
    height: 160px !important;
    width: 160px !important;
  }
  .flow-card-container .container-fluid .flow-card-box-row .card .card-title {
    font-size: 36px;
  }

  .learning-m h1 {
    font-size: 46px !important;
    padding-top: 20px;
  }
  .learning-m p {
    font-size: 38px !important;
    padding-top: 10px;
  }
  .image-hover-text-title {
    font-size: 38px !important;
    padding-top: 10%;
  }
  .image-hover-text-desc {
    font-size: 30px !important;
  }
  .image-hover-text-bubble {
    height: 200% !important;
  }
  .uibtn {
    font-size: 30px !important;
    width: 75% !important;
  }
  .our-project-section .container-fluid .text-over p {
    font-size: 34px !important;
  }
  .our-project-section .container-fluid .text-over .btn-default {
    font-size: 24px;
  }
  .our-project-section .container-fluid {
    min-height: 600px;
  }
  .about_hero {
    min-height: 750px !important;
  }
  .about-hero h1 {
    margin-top: 30% !important;
  }
}

@media (min-width: 1600px) and (max-width: 2000px) {
  .flow-card-container .container-fluid .flow-card-box-row .card .card-title {
    font-size: 26px;
  }

  .uibtn {
    font-size: 22px !important;
    width: 75% !important;
  }
  .btn-floating {
    height: 160px !important;
    width: 160px !important;
  }
}

@media (max-width: 1280px) {
  .uibtn {
    font-size: 13px;
    padding-right: 0;
    padding-left: 0;
  }
}

.carousel-caption {
  bottom: 40px;
}

.caption1 {
  position: absolute;
  right: 15%;
  top: 19%;
  left: 51%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.caption2 {
  position: absolute;
  right: 33%;
  top: 15%;
  left: -29%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.caption3 {
  position: absolute;
  right: 15%;
  left: 49%;
  z-index: 10;
  top: 15%;
  /* padding-top: 20px;
    padding-bottom: 20px; */
  color: #fff;
  text-align: center;
}

.captionheading {
  font-size: 3.1vw;
  color: white;
  font-weight: 100;
}

.captionheadings {
  font-size: 3.1vw;
  color: white;
  font-weight: 100;
  margin-left: 13%;
}

.captionsubheading {
  font-size: 20px;
  color: white;
}

.captionbtn {
  color: white;
  background-color: transparent !important;
  outline: none !important;
  border: 2px solid white;
  margin-top: 20px;
  margin-left: 65px;
  font-size: 1.2vw;
}

.readmorebtn {
  color: white;
  border: 2px solid white;
  margin-top: 50px;
  margin-left: -90px;
  background-color: transparent !important;
  outline: none !important;
  font-size: 1.2vw;
}

.searchicon {
  font-size: 21px;
  color: black !important;
}

.carousel-indicators .active {
  width: 32px;
  height: 3px;
  margin: 0;
  background-color: #fff;
  margin-top: 2px;
  margin-right: 4px;
}

.containerpadding {
  padding-top: 40px;
  margin: auto;
  overflow: hidden;
}

.bg-img {
  background: url("../images/bgimage.webp");
  background-size: cover;
  padding-bottom: 20px;
}

.h2heading1 {
  color: #2579d8;
  font-size: 28px ;
  transition: all 2000ms 0s ease;
  width: 0;
  white-space: nowrap;
  font-weight: 300;
  overflow: hidden;
  padding: 0;
  line-height: 2;
}
.subheader {
  font-size: 26px;
  color: #2579d8;
  font-weight: 100;
}
.bold {
  font-weight: 800;
}
.animate-text .h2heading1 {
  width: 100%;
}
.animate-text-home {
  width: fit-content;
  margin: 0 auto;
}
.animate-text-home h2 {
  font-size: 28px;
}

.h2subheading1 {
  color: #2579d8;
  font-size: 25px;
}

.spanlist {
  color: #2579d8;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
  line-height: 38px;
}

.spanimg {
  width: 20px;
}

.lineimg {
  height: 5px;
}

.name {
  color: #2579d8;
  font-size: 18px;
  margin-top: 50px;
  font-weight: 600;
}

.subname {
  color: #2579d8;
  font-size: 18px;
  font-weight: 400;
}

.gridimg1 {
  width: 102%;
  height: 497px;
}

.gridimg3 {
  height: 238px;
  width: 100%;
  margin-top: 20px;
}

.expheading {
  font-size: 3vw;
  color: #2579d8;
}

.expsubheading {
  font-size: 1vw;
  color: #ff4805;
}

.textcenter {
  text-align: center;
}


.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-left: -104px;
  /* margin-right: -46px; */
}

.gridimg {
  width: 292px;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.vl {
  border-left: 2px solid lightgrey;
  height: 36px;
  padding-right: 10px;
}

.contactnav {
  padding-right: 16px !important;
}

.gridview {
  width: 99%;
  /* width: 87.5%; */
  margin: auto;
  display: grid;
  grid-auto-flow: dense;
  grid-template: "1fr 1fr 1fr 1fr 1fr" 33.33vh "1fr 1fr 1fr 1fr 1fr" 33.33vh "1fr 1fr 1fr 1fr 1fr" 33.33vh;
}

.gridview .box {
  position: relative;
  background: #ccc;
}

.gridview .box.a {
  grid-row: span 2;
}

.gridview .box.f,
.gridview .box.h,
.gridview .box.j {
  grid-column: span 2;
}

.gridview .box img {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%);
  height: 100%;
  min-height: 230px;
  object-fit: cover;
  transition: all 2000ms 0s ease !important;
  width: 100%;
}
.scroll-width .gridview .box img {
  transform: none;
}

@media (max-width: 991px) {
  .gridview {
    grid-template: "1fr 1fr 1fr" 250px "1fr 1fr 1fr" 250px "1fr 1fr 1fr" 250px "1fr 1fr 1fr" 250px "1fr 1fr 1fr" 250px;
  }
}

.gridview .box.h {
  grid-column: span 1;
}

.uibtn {
  background-color: #2579d8 !important;
  position: absolute;
  width: fit-content;
  min-width: 50%;
  padding-left: 6px;
  color: white;
  font-size: 15px;
  bottom: 5%;
  border: 1px solid #2579d8;
}
.ui-btn-long{
    min-width: 75% !important;
}
.learning-m h1 {
  font-size: 24px;
}

.cloudbtn {
  background-color: #2579d8 !important;
  position: absolute;
  top: 72.9%;

  height: 37px;
  width: 163px;
  padding-left: 6px;
  color: white;
  font-size: 15px;
  border: 1px solid #2579d8;
}

.webbtn {
  background-color: #2579d8 !important;
  position: absolute;
  top: 72.9%;
  right: 68.9%;
  height: 37px;
  width: 163px;
  padding-left: 6px;
  color: white;
  font-size: 15px;
  border: 1px solid #2579d8;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: white !important;
  background-color: transparent;
  border-color: #428dabde !important;
}

.btn-default:hover {
  color: white !important;
  background-color: #e6e6e6;
  border-color: #2579d8;
}

.content {
  position: absolute;
  bottom: 0;
  /* Fallback color */
  background: #2579d8;
  /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  padding: 64px;
  height: 100%;
  font-size: 18px;
  left: 0px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  .content {
    position: absolute;
    bottom: 0;
    /* Fallback color */
    background: #2579d8;
    /* Black background with 0.5 opacity */
    color: #f1f1f1;
    width: 100%;
    padding: 64px;
    height: 200px;
    font-size: 10px;
    left: 0px;
    h1 {
      font-size: 18px;
    }
  }
}

.img {
  width: 100%;
  z-index: 0;
}

.text-over-img {
  z-index: 1;
  font-size: 18px;
  margin-top: 10%;
  background-color: #2579d8;
  width: 49%;
  margin-left: -14px;
  padding: 44px;
  min-height: 262px;
  color: white !important;
  position: absolute;
}

.whiteparagarph {
  color: white;
  font-size: 16px;
}

.viewbtn {
  font-size: 21px;
  color: white;
  background-color: transparent !important;
  height: 51px;
  width: 341px;
  outline: none !important;
  border: 2px solid white;
  margin-top: 14px;
}

.viewbtn:hover {
  font-size: 21px;
  color: white;
  background-color: #000 !important;
  height: 51px;
  width: 341px;
  outline: none !important;
  border: 2px solid white;
  margin-top: 14px;
}

.lineproject {
  height: 17px;
  width: 143px;
}

.projectcontainer {
  padding-top: 130px;
}

.commoncontainer {
  padding-top: 40px;
}

.rowpadding {
  padding-top: 50px;
}

.serveimg {
  width: 81px;
  margin: auto;
}

.fullimgnew {
  background-image: url("../images/background-btm.jpeg");
  background-color: #cccccc;
  min-height: 312px;
  opacity: 0.88;
  background-size: cover;
  background-repeat: no-repeat;
}

.text-over-imgnew {
  z-index: 1;
  font-size: 18px;
  margin-top: 12%;
  background-color: #2579d8;
  width: 100%;
  margin-left: 0px;
  padding: 44px;
  min-height: 312px;
  color: white !important;
  padding-top: 90px;
}

.clientpadding {
  margin-left: 52px;
}

.clientsubheading {
  font-size: 18px;
}

.service1:hover img {
  transform: scale(1.1);
}

.service1 img {
  max-height: 253px;
  width: 100%;
  border-radius: 8px;
  transition: all 500ms 0s ease;
}

.servicebox1 {
  z-index: 1;
  font-size: 18px;
  margin-top: -24%;
  background-color: #2579d8;
  width: 70%;
  margin-left: 0px;
  padding: 17px;
  min-height: 200px;
  color: white !important;
  /* padding-top: 90px; */
  position: absolute;
  padding-top: 31px;
  transition: all 500ms 0s ease;
}

.service2 {
  background-image: url("../images/success2.png");
  background-color: #cccccc;
  min-height: 253px;
  width: 100%;
  border-radius: 8px;
}

.servicebox2 {
  z-index: 1;
  font-size: 18px;
  margin-top: 31%;
  background-color: #2579d8;
  width: 70%;
  margin-left: 0px;
  padding: 17px;
  min-height: 200px;
  color: white !important;
  /* padding-top: 90px; */
  position: absolute;
  padding-top: 31px;
}

.service3 {
  background-image: url("../images/success3.png");
  background-color: #cccccc;
  min-height: 253px;
  width: 100%;
  border-radius: 8px;
}

.servicebox3 {
  z-index: 1;
  font-size: 18px;
  margin-top: 31%;
  background-color: #2579d8;
  width: 70%;
  margin-left: 0px;
  padding: 17px;
  min-height: 200px;
  color: white !important;
  /* padding-top: 90px; */
  position: absolute;
  padding-top: 31px;
}

.redbtn {
  background-color: #ff4805;
  height: 30px;
  width: 180px;
  border-radius: 40px;
  color: white;
  font-size: 13px;
}

.redbtn:hover {
  background-color: #ff4805;
  height: 30px;
  width: 180px;
  border-radius: 40px;
  color: white;
  font-size: 13px;
}

.whiteparagarphs {
  color: white;
  font-size: 19px;
  margin-top: 20px;
}

.servicecontainer {
  margin-top: 73px;
}

.storybtn {
  font-size: 21px;
  color: #2579d8;
  background-color: transparent !important;
  height: 51px;
  width: 341px;
  outline: none !important;
  border: 2px solid white;
  margin-top: 120px;
  border: 2px solid #2579d8;
}
.story-logo-img {
  margin: 10px 0 5px 0;
  display: block;
  max-height: 30px;
}

.storybtn:hover {
  font-size: 21px;
  color: #fff !important;
  background-color: #000 !important;
  height: 51px;
  width: 341px;
  outline: none !important;
  border: 2px solid white;
  margin-top: 120px;
  border: 2px solid #2579d8;
}

.contactimage {
  width: 39px;
}

.contactheading {
  font-size: 25px;
  color: #2579d8;
}

.contactcontainer {
  padding-top: 80px;
}
.dropdown-menu{
    border: none;
    box-shadow: 0 10px 20px rgb(80 80 80 / 10%);
}

.footercontainer {
  background-image: url("../images/footerimg.png") !important;
  padding: 60px 0;
  // margin-top: 30px;
  background: #dde7f3;
  
}
.footerbottom {
  background: #2579d8;
  height: 40px;
  padding: 10px 10px 10px 0;
}
.footerbottom p {
  color: #fff;
  text-align: right;
}

.footerparagraph {
  color: #2579d8;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 1.9;
  cursor: pointer;
}

.footerheading {
  color: #2579d8;
  font-size: 22px;
  text-align: left;
}

.footerline {
  margin-bottom: 30px;
  height: 10px;
  width: 125px;
}

.form-control {
  display: block;
  width: 100%;
  /* height: calc(1.5em + .75rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 45px;
  color: white;
}

.submitbtn {
  font-size: 21px;
  color: #2579d8;
  background-color: transparent !important;
  height: 51px;
  width: 120px;
  outline: none !important;
  border: 2px solid #2579d8;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}

.submitbtn:hover {
  font-size: 21px;
  color: #2579d8;
  background-color: transparent !important;
  height: 51px;
  width: 120px;
  outline: none !important;
  border: 2px solid #2579d8;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}

.animatedline {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.animatedline-two {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 300px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.animatedline-three {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 200px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.animatedline-four {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 127px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.animatedline-five {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 167px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.carousel-indicators {
  display: none !important;
}


.animateline .line {
  height: 3px;
  background: #ff4805;
//   position: absolute;
//   top: 0px;
  /* left: 37%; */
  width: 100% !important;

  -webkit-animation: dude 0.75s 0.5 forwards;
  -moz-animation: dude 0.75s 0.5 forwards;
  -o-animation: dude 0.75s 0.5 forwards;
  animation: dude 0.75s 0.5 forwards;
}

@-webkit-keyframes dude {
  0% {
    width: 0;
  }

  100% {
    width: 35%;
  }
}

@-moz-keyframes dude {
  0% {
    width: 0;
  }

  100% {
    width: 35%;
  }
}

@-o-keyframes dude {
  0% {
    width: 0;
  }

  100% {
    width: 35%;
  }
}

@keyframes dude {
  0% {
    width: 0;
  }

  100% {
    width: 35%;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: none;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.imagebox:hover .gridimg1 {
  transform: scale(1.1);
}

.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.image-box img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}

.image-box:hover img {
  transform: scale(1.1);
}

#gridhr {
  height: 3px;
  width: 50%;
  background-color: #ff4805;
}

#gridhr1 {
  height: 3px;
  width: 15%;
  background-color: #ff4805;
}

.image-hover-text-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: auto;
}

.image-hover-image {
  display: block;
}

.image-hover-text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s linear;
}

.image-hover-text:hover {
  opacity: 1;
}

.image-hover-text-bubble {
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 100%;
  height: 150%;
  text-align: center;
  background: #2579d8;
  border: 1px solid #2579d8;
  margin: 0 auto;
  padding-top: 150px;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  font-size: 17px;
  text-align: center;
  word-wrap: break-word;
  color: white;
}

.image-hover-text-title {
  font-size: 17px;
  display: block;
}

.image-hover-text-desc {
  font-size: 14px;
}

.image-hover-text-container1 {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: auto;
  transition: all 0.2s linear;
}

.image-hover-text1 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s linear;
}

.image-hover-text1:hover {
  opacity: 1;
}

.image-hover-text-bubble1 {
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 100%;
  height: 100%;
  text-align: center;
  background: #2579d8;
  border: 1px solid #2579d8;
  margin: 0 auto;
  padding-top: 60px;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  text-align: center;
  word-wrap: break-word;
  color: white;
}

.image-hover-text-container2 {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: auto;
  transition: all 0.2s linear;
}

.image-hover-text2 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s linear;
}

.image-hover-text2:hover {
  opacity: 1;
}

.focusAreaView div img {
  width: 100%;
  min-height: 200px;
}
.focusAreaView div {
  position: relative;
}
.focusAreaView button {
  position: absolute;
  bottom: 10%;
  left: 0;
  max-height: 40px;
}

.image-hover-text-bubble2 {
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 100%;
  height: 100%;
  text-align: center;
  background: #2579d8;
  border: 1px solid #2579d8;
  margin: 0 auto;
  padding-top: 40px;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  text-align: center;
  word-wrap: break-word;
  color: white;
}

.fade-in {
  animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.footercolumn {
  padding-top: 58px;
}

.assistimg {
  height: 250px;
  width: 100%;
}

.assistheading {
  font-size: 66px;
  color: gray;
  font-weight: 600;
}

.assistsubparagraph {
  font-size: 18px;
  color: #2579d8;
  font-weight: 600;
}

.assistsubparagraph1 {
  font-size: 17px;
  color: #7c7c7c;
}

.secondcolumn {
  margin-top: 122px;
  margin-left: -30px;
}

.thirdcolumn {
  margin-top: 231px;
  margin-left: -30px;
}

.forthcolumn {
  margin-top: 354px;
  margin-left: -30px;
}

.assistrowpadding {
  margin-left: 68px;
  padding-top: 50px;
}

.body {
  font-style: Nunito Sans;
}

.carousel-indicators .active {
  width: 32px;
  height: 3px;
  margin: 0;
  background-color: #fff;
  margin-top: 2px;
  margin-right: 4px;
}

.paragraphheading {
  color: #7c7c7c;
  font-size: 16px;
}

.h2subheading1 {
  color: #2579d8;
  font-size: 25px;
}

.lineimg {
  height: 5px;
}

.name {
  color: #2579d8;
  font-size: 18px;
  margin-top: 20px;
}

.subname {
  color: #2579d8;
  font-size: 18px;
  font-weight: 400;
}

.gridimg1 {
  width: 102%;
  height: 497px;
}

.gridimg2 {
  width: 100%;
  margin-bottom: -20px;
}

.gridimg3 {
  height: 238px;
  width: 100%;
  margin-top: 20px;
}

.btn-floating {
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 140px;
  width: 140px;
  padding-left: 10px;
  padding-top: 10px;
}

.expsubheading {
  font-size: 19px;
  color: #ff4805;
}

.textcenter {
  text-align: center;
}

.carouselimg {
  width: 100%;
}

.react-multi-carousel-item {
  width: 252px !important;
}
.carousel-item img {
  max-height: 840px !important;
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-left: -104px;
  /* margin-right: -46px; */
}

.gridimg {
  width: 292px;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.fullimg {
  background-color: #ffffff;
  min-height: 450px;
  opacity: 0.88;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.fullimg video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.7;
}

.vl {
  border-left: 2px solid lightgrey;
  height: 36px;
  padding-right: 10px;
}

.contactnav {
  padding-right: 16px !important;
}

.gridview {
  width: 99%;
  /* width: 87.5%; */
  margin: auto;
  display: grid;
  grid-auto-flow: dense;
  grid-template: "1fr 1fr 1fr 1fr 1fr" 33.33vh "1fr 1fr 1fr 1fr 1fr" 33.33vh "1fr 1fr 1fr 1fr 1fr" 33.33vh;
  background: transparent;
}

.gridview .box {
  position: relative;
  background: transparent;
}

.gridview .box.a,
.gridview .box.e {
  grid-row: span 2;
}

.gridview .box.f,
.gridview .box.h,
.gridview .box.j {
  grid-column: span 2;
}

@media (max-width: 991px) {
  .gridview {
    grid-template: "1fr 1fr 1fr" 250px "1fr 1fr 1fr" 250px "1fr 1fr 1fr" 250px "1fr 1fr 1fr" 250px "1fr 1fr 1fr" 250px;
  }
}

@media (max-width: 768px) {
  //   .gridview {
  //     grid-template: "1fr 1fr" 200px "1fr 1fr" 200px "1fr 1fr" 200px "1fr 1fr" 200px "1fr 1fr" 200px "1fr 1fr" 200px "1fr 1fr" 200px;
  //   }
  .clients-carousel div img{
      width: 100%;
  }
  .mobile-image img{
      max-width: 60%;
  }
  .flow-card-box-row .card-body{
      padding-right:0 !important;
      padding-left:0 !important;

  }
  .learning-cap-img {
    margin: 50px auto !important;
    width: 80%;
    transform: translate(100px);
  }

  #footeranimate {
    margin-left: 0 !important;
  }

  .animate-text {
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    animation: none !important;
    opacity: 1 !important;
  }
  .h2heading1 {
    width: 100% !important;
  }
  .animateline {
   
    width: 100%;
    margin: 0 auto !important;
    
  }
  .animatedline .animated,.no-animation{
      animation: none !important;
      width: 100% !important;
    margin: 0 auto !important;
    opacity: 1 !important;

  }
  .animateline .line{
      animation: none !important;
      width: 100% !important;
    margin: 0 auto !important;

  }
  .gridview {
    display: block;
  }
  .gridview div {
    display: block;
    grid-row: none;
  }
  .gridview .box img {
    position: relative;
  }
  .caption1,
  .caption2,
  .caption3 {
    top: 0;
  }
  .captionbtn {
    margin-top: 5px;
    padding: 5px;
    white-space: nowrap;
  }
  .readmorebtn {
    margin-top: 5px;
    margin-left: 0;
  }
  .h2heading1 {
    font-size: 24px ;
  }
  .subheader {
    font-size: 22px;
    padding-top: 10px;
  }

  .containerpadding {
    padding-top: 30px;
  }
  .infobar-container .container-fluid {
    padding-top: 50px;
  }
  .clientheading {
    font-size: 22px !important;
  }
  .clientsubheading {
    font-size: 12px !important;
    white-space: nowrap;
  }
  .infobar-container .container-fluid .icon-container {
    height: 60px;
    width: 60px;
    padding-top: 15px;
  }
  .infobar-container .container-fluid .icon-container img {
    width: 50% !important;
  }
  .story-box-container .container .box-container {
    margin-top: 0;
  }
  .story-box-container {
    padding-bottom: 0;
  }
  .story-box-container .alice-carousel__wrapper {
    min-height: 350px;
  }
  .footer-cont {
    max-width: 90% !important;
  }
  .footer-cont .col-4 {
    padding-right: 0;
  }
}

.gridview .box.h {
  grid-column: span 1;
}

.cloudbtn {
  background-color: #2579d8 !important;
  position: absolute;
  top: 72.9%;

  height: 37px;
  width: 163px;
  padding-left: 6px;
  color: white;
  font-size: 15px;
  border: 1px solid #2579d8;
}

.webbtn {
  background-color: #2579d8 !important;
  position: absolute;
  top: 72.9%;
  right: 68.9%;
  height: 37px;
  width: 163px;
  padding-left: 6px;
  color: white;
  font-size: 15px;
  border: 1px solid #2579d8;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: white !important;
  background-color: transparent;
  border-color: #428dabde !important;
}

.btn-default:hover {
  color: white !important;
  background-color: #e6e6e6;
  border-color: #2579d8;
}

.img {
  width: 100%;
  z-index: 0;
}

.text-over-img {
  z-index: 1;
  font-size: 18px;
  margin-top: 10%;
  background-color: #2579d8;
  width: 49%;
  margin-left: -14px;
  padding: 44px;
  min-height: 262px;
  color: white !important;
  position: absolute;
}

.whiteparagarph {
  color: white;
  font-size: 16px;
}

.viewbtn {
  font-size: 21px;
  color: white;
  background-color: transparent !important;
  height: 51px;
  width: 341px;
  outline: none !important;
  border: 2px solid white;
  margin-top: 14px;
}

.viewbtn:hover {
  font-size: 21px;
  color: white;
  background-color: #000 !important;
  height: 51px;
  width: 341px;
  outline: none !important;
  border: 2px solid white;
  margin-top: 14px;
}

.lineproject {
  height: 17px;
  width: 143px;
}

.projectcontainer {
  padding-top: 130px;
}

.rowpadding {
  padding-top: 50px;
}

.serveimg {
  width: 81px;
  margin: auto;
}

.serveheading {
  color: #7c7c7c;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding-top: 15px;
}

.servecard {
  box-shadow: 0 10px 20px rgb(80 80 80 / 10%);
  -webkit-box-shadow: 0 10px 20px rgb(80 80 80 / 10%);
  -moz-box-shadow: 0 10px 20px rgb(80 80 80 / 10%);
  padding: 12% 0;
  width: 85%;
  margin: auto;
  border-radius: 22px;
  margin-top: 1rem;
  border: 3px solid transparent;
  transition: all 500ms 0s ease;
}

.servecard:hover {
  border: 3px solid #ff4805;
}

.clientpadding {
  margin-left: 52px;
}

.clientheading {
  font-size: 35px;
  margin-top: 10px;
}

.clientsubheading {
  font-size: 18px;
}

.service1:hover img {
  transform: scale(1.1);
}

.service1 img {
  max-height: 253px;
  width: 100%;
  border-radius: 8px;
  transition: all 500ms 0s ease;
}

.servicebox1 {
  z-index: 1;
  font-size: 18px;
  margin-top: -24%;
  background-color: #2579d8;
  width: 70%;
  margin-left: 0px;
  padding: 17px;
  min-height: 200px;
  color: white !important;
  /* padding-top: 90px; */
  position: absolute;
  padding-top: 31px;
  transition: all 500ms 0s ease;
}

.service2 {
  background-image: url("../images/success2.png");
  background-color: #cccccc;
  min-height: 253px;
  width: 100%;
  border-radius: 8px;
}

.servicebox2 {
  z-index: 1;
  font-size: 18px;
  margin-top: 31%;
  background-color: #2579d8;
  width: 70%;
  margin-left: 0px;
  padding: 17px;
  min-height: 200px;
  color: white !important;
  /* padding-top: 90px; */
  position: absolute;
  padding-top: 31px;
}

.service3 {
  background-image: url("../images/success3.png");
  background-color: #cccccc;
  min-height: 253px;
  width: 100%;
  border-radius: 8px;
}

.servicebox3 {
  z-index: 1;
  font-size: 18px;
  margin-top: 31%;
  background-color: #2579d8;
  width: 70%;
  margin-left: 0px;
  padding: 17px;
  min-height: 200px;
  color: white !important;
  /* padding-top: 90px; */
  position: absolute;
  padding-top: 31px;
}

.redbtn {
  background-color: #ff4805;
  height: 30px;
  width: 180px;
  border-radius: 40px;
  color: white;
  font-size: 13px;
}

.redbtn:hover {
  background-color: #ff4805;
  height: 30px;
  width: 180px;
  border-radius: 40px;
  color: white;
  font-size: 13px;
}

.whiteparagarphs {
  color: white;
  font-size: 19px;
  margin-top: 20px;
}

.servicecontainer {
  margin-top: 73px;
}

.storybtn {
  font-size: 21px;
  color: #2579d8;
  background-color: transparent !important;
  height: 51px;
  width: 341px;
  outline: none !important;
  border: 2px solid white;
  margin-top: 120px;
  border: 2px solid #2579d8;
}

.storybtn:hover {
  font-size: 21px;
  color: #fff !important;
  background-color: #000 !important;
  height: 51px;
  width: 341px;
  outline: none !important;
  border: 2px solid white;
  margin-top: 120px;
  border: 2px solid #2579d8;
}

.contactimage {
  width: 39px;
}

.contactheading {
  font-size: 25px;
  color: #2579d8;
}

.contactcontainer {
  padding-top: 80px;
}

.footer-cont {
  max-width: 80%;
  margin: 0 auto;
}

.footerline {
  margin-bottom: 30px;
  height: 10px;
  width: 125px;
}
.social-icon {
  height: 20px;
  width: 20px;
  vertical-align: text-top;
}

.submitbtn {
  font-size: 21px;
  color: #2579d8;
  background-color: transparent !important;
  height: 51px;
  width: 120px;
  outline: none !important;
  border: 2px solid #2579d8;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}

.submitbtn:hover {
  font-size: 21px;
  color: #2579d8;
  background-color: transparent !important;
  height: 51px;
  width: 120px;
  outline: none !important;
  border: 2px solid #2579d8;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}

.animatedline {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.animatedline-two {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 300px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.animatedline-three {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 200px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.animatedline-four {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 127px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.animatedline-five {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: 167px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.carousel-indicators {
  display: none !important;
}

.animatedline-two .line,
.animatedline-three .line {
  height: 4px;
  background: #ff4805;
  position: absolute;
  top: 0px;
  /* left: 37%; */
  width: 100% !important;

  -webkit-animation: dude 0.75s 0.5 forwards;
  -moz-animation: dude 0.75s 0.5 forwards;
  -o-animation: dude 0.75s 0.5 forwards;
  animation: dude 0.75s 0.5 forwards;
}

.animatedline-four .line {
  height: 4px;
  background: #ff4805;
  position: absolute;
  top: 0px;
  /* left: 37%; */
  width: 70% !important;

  -webkit-animation: dude 0.75s 0.5 forwards;
  -moz-animation: dude 0.75s 0.5 forwards;
  -o-animation: dude 0.75s 0.5 forwards;
  animation: dude 0.75s 0.5 forwards;
}

.animatedline-five .line {
  height: 4px;
  background: #ff4805;
  position: absolute;
  top: 0px;
  /* left: 37%; */
  width: 70% !important;

  -webkit-animation: dude 0.75s 0.5 forwards;
  -moz-animation: dude 0.75s 0.5 forwards;
  -o-animation: dude 0.75s 0.5 forwards;
  animation: dude 0.75s 0.5 forwards;
}

.animatedline .line {
  height: 4px;
  background: #ff4805;
  position: absolute;
  top: 10px;
  -webkit-animation: dude 0.75s 1 forwards;
  -moz-animation: dude 0.75s 1 forwards;
  -o-animation: dude 0.75s 1 forwards;
  animation: dude 0.75s 1 forwards;
}

@-webkit-keyframes dude {
  0% {
    width: 0;
  }

  100% {
    width: 35%;
  }
}

@-moz-keyframes dude {
  0% {
    width: 0;
  }

  100% {
    width: 35%;
  }
}

@-o-keyframes dude {
  0% {
    width: 0;
  }

  100% {
    width: 35%;
  }
}

@keyframes dude {
  0% {
    width: 0;
  }

  100% {
    width: 35%;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: none;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.imagebox:hover .gridimg1 {
  transform: scale(1.1);
}

.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.image-box img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}
.collapse-btn {
  background: none;
  color: #2579d8;
  border: none;
  font-weight: 600;
  font-size: 15px;
  margin: 5px 0;
  display: flex;
  padding-left: 0;
}
.collapse-btn svg {
  color: #fff;
  background: #ccc;
  border-radius: 50%;
  height: 1.4rem;
  width: 1.4rem !important;
  padding: 2px;
  vertical-align: middle;
  margin: 0 5px;
}
.readmore svg {
  background: none;
  color: #2579d8;
  margin: 0 !important;
}
.image-box:hover img {
  transform: scale(1.1);
}

#gridhr {
  height: 3px;
  width: 50%;
  background-color: #ff4805;
}

#gridhr1 {
  height: 3px;
  width: 15%;
  background-color: #ff4805;
}

.image-hover-text-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: auto;
  transition: all 0.2s linear;
}

.image-hover-image {
  display: block;
}

.image-hover-text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s linear;
}

.image-hover-text:hover {
  opacity: 1;
}

.image-hover-text-bubble {
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 100%;
  height: 150%;
  text-align: center;
  background: #2579d8;
  border: 1px solid #2579d8;
  margin: 0 auto;
  padding-top: 150px;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  font-size: 17px;
  text-align: center;
  word-wrap: break-word;
  color: white;
}

.image-hover-text-title {
  font-size: 17px;
  display: block;
}

.image-hover-text-desc {
  font-size: 14px;
}

.image-hover-text-container1 {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: auto;
  transition: all 0.2s linear;
}

.image-hover-image1 {
  display: block;
}

.image-hover-text1 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s linear;
}

.image-hover-text1:hover {
  opacity: 1;
}

.image-hover-text-bubble1 {
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 100%;
  height: 100%;
  text-align: center;
  background: #2579d8;
  border: 1px solid #2579d8;
  margin: 0 auto;
  padding-top: 7%;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  text-align: center;
  word-wrap: break-word;
  color: white;
}

.image-hover-text-container2 {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: auto;
  transition: all 0.2s linear;
}

.image-hover-image2 {
  display: block;
}

.image-hover-text2 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s linear;
}

.image-hover-text2:hover {
  opacity: 1;
}

.image-hover-text-bubble2 {
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 100%;
  height: 100%;
  text-align: center;
  background: #2579d8;
  border: 1px solid #2579d8;
  margin: 0 auto;
  padding-top: 40px;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  text-align: center;
  word-wrap: break-word;
  color: white;
}

.fade-in {
  animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.footercolumn {
  padding-top: 58px;
}

.assistimg {
  height: 250px;
  width: 100%;
}

.assistheading {
  font-size: 66px;
  color: gray;
  font-weight: 600;
}

.assistsubparagraph {
  font-size: 18px;
  color: #2579d8;
  font-weight: 600;
}

.assistsubparagraph1 {
  font-size: 17px;
  color: #7c7c7c;
}

.secondcolumn {
  margin-top: 122px;
  margin-left: -30px;
}

.thirdcolumn {
  margin-top: 231px;
  margin-left: -30px;
}

.forthcolumn {
  margin-top: 354px;
  margin-left: -30px;
}

.assistrowpadding {
  margin-left: 68px;
  padding-top: 50px;
}

// industries we serve carousel

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 10% !important;
  padding: 10px 5px;
  position: absolute !important;
  top: 85px;
}
.story-box-container .alice-carousel__prev-btn,
.story-box-container .alice-carousel__next-btn {
  top: 30%;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 2px 5px;
  margin: 0;
  color: #465798;
  height: 25px;
  width: 25px;
  background: #ccc;
  border-radius: 50%;
  // font-size: 25px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 1.4;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  padding: 0;
}
.alice-carousel__prev-btn {
  left: 0;
  text-align: left;
}
.alice-carousel__next-btn {
  right: 0;
  text-align: right;
}
.industries-carousel {
  width: 98%;
  margin: 0 auto;
}

// sidebar menu
.navigation-sidebar {
  background: #fff;
  height: 100%;
  max-width: 300px;
  width: 70%;
  box-shadow: 0 10px 20px rgb(80 80 80 / 10%);
  -webkit-box-shadow: 0 10px 20px rgb(80 80 80 / 10%);
  -moz-box-shadow: 0 10px 20px rgb(80 80 80 / 10%);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;
  transform: translateX(100%);
  transition: all 500ms 0s ease;
  overflow-y: scroll;
}
.navigation-sidebar.open {
  transform: translateX(0);
}
.header-mobile-btn {
  background: none;
  border: none;
  position: absolute;
  top: 30%;
}
.header-mobile-btn svg {
  height: 30px;
  width: 30px !important;
  color: #666;
}
 
.story-box-container .slick-prev {
  top:25%;
  left:0;
  z-index: 1;

}
.story-box-container .slick-next{
    top:25%;
    right:0;
    z-index: 1;
}
.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
  border-radius: 50%;
  z-index: 1;
}
.slick-next {
  right: -20px;
}
.slick-prev {
  left: -20px;
}
.navbar .navbar-nav .nav-link.active{
    background: transparent;
}
// search bar
.togglesearch{
    background: #E8E8E4;
    position: absolute;
    top: 85px;
    right: 75px;
    width: auto;
    height: auto;
    line-height: 35px;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    display: block;
    z-index: 1;
  }
   
  .togglesearch:before{
    content: "";
    position: absolute;
    top: -28px;
    right: 5px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 14px solid transparent;
    border-bottom: 14px solid #2579d8;
  }
   
  .togglesearch input[type="text"]{
    width: 260px;
    padding: 5px 10px;
    margin-left: 0;
    border: 1px solid #2579d8;
    outline: none;
    margin-top: 0;
   
  }
   
  .togglesearch button{
    width: 30px;
    padding: 5px 0;
    background: #2579d8;
    color: #fff;
    margin-left: -6px;
    border: 1px solid #2579d8;
    outline: none;
    cursor: pointer;
  }
  .searchresult{
      position: absolute;
      top:132px;
      right: 75px;
      z-index: 100;
      background: #fff;
      width: 283px;
      max-height: 60vh;
      overflow-y: auto;
      box-shadow:0 10px 20px rgb(80 80 80 / 10%);

  }
  .searchresult ul{
      margin:0;
      padding: 0;
      
  }
  .searchresult ul li{
      list-style: none;
      text-transform: capitalize;
      line-height: 1.9;
      
  }
  .searchresult ul li a{
      color: #7c7c7c;
      font-size: 16px;
      padding: 10px;
  }
 
  

  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c2c2c2; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ccc; 
  }
