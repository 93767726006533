.qahero{
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/qatesting/qa-quality-assurance-and-quality-control-concept-181467126.png");
    width: 100%;
    float: left;
    
    background-size: cover;
    background-repeat: no-repeat;
}
.qabg{
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/qatesting/standard-quality-control-certification-assurance-guarantee-internet-business-technology-concept-122403089.png") !important;
    padding: 10%;
    color: #fff;
    background-size: 100% !important;
}
.webmaster .container-fluid .detail-section .image-detail img{
    width: 100%;
    margin-top: 25%;
}
.webmaster-img img{
    width: 100% !important;
    padding-top: 25%;
}



.qa-process-web{
    background: url("../../images/qatesting/qaArrow.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    margin:0 auto 80px auto;
    display: block;
    max-width: 500px;
   
}
.qa-mobile-process p{
    font-size: 13px;
    line-height: 1.5;
    padding-top: 5px;
}
.qa-mobile-process.active{
    background: #2579d8;
    font-weight: bold;
}