.timeline-contact {
    position: relative;
    border-color: rgba(160, 175, 185, .15);
    padding: 0;
    margin: 0;
    max-width: 80%;
}
.tl-content{
    font-size: 16px;
    color: #7c7c7c;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.tl-item {
    border-radius: 3px;
    position: relative;
    display: -ms-flexbox;
    display: flex
}

.tl-item>* {
    padding: 10px 10px 30px 30px;
}

.tl-item:last-child .tl-dot:after {
    display: none
}


.tl-item:last-child .tl-dot:after {
    display: none
}


.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, .15)
}

.tl-dot:after,
.tl-dot:before {
   content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    

}
.tl-dot:before{
    content: '1';
    background-color: #FF4805;
    text-align: center;
    color: #fff;
    z-index: 1;
   
}
.tl-dot-2:before{
    content: '2';
}
.tl-dot-3:before{
    content: '3';
}
.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0
}

.tl-heading{
    color: #2579D8;
    font-size: 22px;
    padding-bottom: 10px;
}


.tl-dot {
    position: relative;
    border-color: #E5D3D3;
}


.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0
}

.tl-content p:last-child {
    margin-bottom: 0
}



.contact-intro{
font-size: 16px;
width: 90%;
margin: 3em 0 0 10px;
color: #7c7c7c;
}
.contact-form{
    border: 1px solid #2579D8;
border-radius: 8px;
padding:30px;
margin-top: 50px;
margin-bottom: 50px;
}
.contact-form h2{
    color: #2579D8;
    font-size: 24px;
    margin-bottom: 30px;
}
.contact-form form .row{
    margin-bottom: 30px;
}
.contact-form form input.button{
    display: block;
    margin:0 auto;
    border: 1px solid #428ADB;
    color: #428ADB;
    text-align: center;
    font-size: 22px;
    background: #fff;
    box-shadow: none;
    padding:5px 35px;
}
.contact-form form input, .contact-form form textarea{
    border:1px solid #428ADB;
}
.contacthero{
    height: 30vw !important;

}
.contacthero img{
    width: 100%;
}
.contact-padding{
    padding-right: 3rem;
    padding-left: 3rem;
}
@media (max-width:768px){
    .contact-padding{
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .contact-intro{
font-size: 18px;
    }
}
/* contact form styling */
#float-label {
    display: flex;
    flex-direction: column;
   
  }
  
  #float-label input {
    width: 100%;
    height: 56px;
    padding: 10px 16px 0 10px;
    outline: 0;
    border: 1px solid #2579D8;
    border-radius: 4px;
    background: #fff;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    
  }
  #float-label textarea{
    width: 100%;
    padding: 20px 16px 0 10px;
    outline: 0;
    border: 1px solid #2579D8;
    border-radius: 4px;
    background: #fff;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
  }
  
  #float-label label {
    font-size: 16px;
    font-family: "Nunito Sans", sans-serif;
    padding: 0 12px;
    color: #9c9c9c;
    pointer-events: none;
  }
  #float-label {
   
    position: relative;
  }
  #float-label label {
    font-family: "Nunito Sans", sans-serif;
    position: absolute;
    transform: translate(0, -2px) scale(1);
  }
  #float-label label {
   
    transform-origin: top left;
    transition: all 0.2s ease-out;
    
  }
  
  #float-label:focus-within label {
    transform: translate(0, 8px) scale(0.75);
    color: #FF4805;
    font-weight: bold;
  }
  #float-label .Active {
    transform: translate(0, 8px) scale(0.75);
    color: #FF4805;
    font-weight: bold;
  }