.emasterhero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../images/emasters/pexels-bruno-bueno-3854816.png");
  width: 100%;
  float: left;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.emaster-about-img {
  background: url("../../images/emasters/emaster-bg-1.svg");
  max-height: 500px;
  max-width: 500px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.emaster-about-img img {
  max-width: 65%;
  margin: 0 auto;
  display: block;
  transform: translateY(-60px);
}
.emaster-about-img img:hover {
  transform: translateY(-60px);
}
.emastercontainer {
  padding: 150px 5% 50px 5%;
  margin: auto;
  overflow: hidden;
  /* background-color: rgb(250, 250, 250,1); */
}
.emasterslogo {
  display: block;
  width: 50%;
  margin-bottom: 20px;
}
.emaster-btn {
  text-transform: uppercase;
  color: #428adb;
  border: 1px solid #428adb;
  background: none;
  box-shadow: none;
  padding: 8px 35px;
  font-size: 16px;
}
.emaster-btn:hover {
  background-color: black;
  color: #fff;
}
.emastercontainer-2 {
  padding: 50px 5% 50px 5%;
  margin: auto;
  overflow: hidden;
  background-color:#f8f8f8;
}
.emastercontainer-2 img {
  width: 100%;
  position: relative;
}
.app-sc-line-a{
    position: absolute;
    bottom: 25%;
    right: 60%;
    color: #fff;
    font-size: 16px;
}
.app-sc-line-b{
    position: absolute;
    bottom: 25%;
    left: 60%;
    color: #fff;
    font-size: 16px;
    
}
.app-sc-line-b img{
    -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 90px;
    height: 12px;
    margin-right: 10px;
}
.app-sc-line-a img{
    width: 100px;
    height: 12px;
    margin-left: 10px;

}
.app-sc-name{
    font-size: 16px;
    color: #fff;
    text-align: center;
    display: block;
}
/* emaster app features */
.app-feature-card, .mobile-app-feature-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
  border: 1px solid #f4f4f4;
  padding: 20px;
  position: relative;
  margin-bottom: 30px;
}
.app-feature-card h3, .mobile-app-feature-card h3 {
  font-size: 22px;
  color: #2579d8;
  width: 85%;
}
.app-feature-card h1, .mobile-app-feature-card h1 {
  color: #dddddd;
  margin-top: -10px;
  padding: 0;
  font-size: 36px;
  text-align: right;
}
.app-feature-card svg, .mobile-app-feature-card svg {
  color: #ff4805;
  margin: 2px 5px;
}
.app-feature-card:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background: #ff4805;
  top: 20%;
  right: -20px;
  border-radius: 50%;
}
.right-card:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background: #ff4805;
  top: 20%;
  left: -20px;
  border-radius: 50%;
}
.right-card:after {
  content: "";
  position: absolute;
  /* height: 2px; */
  width: 145px;
  /* background: #FF4805; */
  top: 24%;
  left: -165px;
  /* border-radius: 50%; */
  border: 1px dashed #000;
  z-index: 1;
}
.app-feature-card:after {
  content: "";
  position: absolute;
  /* height: 2px; */
  width: 145px;
  /* background: #FF4805; */
  top: 24%;
  right: -165px;
  /* border-radius: 50%; */
  border: 1px dashed #000;
  z-index: 1;
}

.mobile-image {
  background-image: url("../../images/emasters/Path\ 170.svg");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
}
.mobile-image img {
  width: auto;
  margin: -30px auto 0 auto;
  display: block;
  max-height: 750px;
  z-index: 5;
  
}
.animate-emaster-line{
    margin:25px 0;
}
.animate-emaster-line div{
    width: 100% !important;
    transition: all 4000ms 0s ease;;
}

.emaster-flow-chart img {
  width: 100%;
}
 
.app-features img {
  display: block;
  max-width: 100%;
  opacity: 1;
  margin: 0 auto;
}
.screens-bg {
  background-image: url("../../images/emasters/Path\ 386.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 200px;
  background-position: center;
}
.app-sc-a {
  max-width: 50%;
  margin: -10em 5em 0 auto;
  display: block;
}
.app-sc-b {
  margin: -25em 0 0 5em;
}
.app-sc-c {
  margin-top: -8em;
  margin-right: 5em;
}
.brand-text h4 {
  display: block;
  width: 100%;
  padding: 15px;
}
.brand-text img {
  padding: 0 15px;
  max-width: 100%;
}
.brand-text h4:first-child {
  color: #2579d8;
}
.brand-text h4 {
  color: #5d5d5d;
  font-size: 24px;
}
.brand-text h4 span.light {
  font-weight: 1;
}
.txt {
  color: #ff4805;
  font-size: 36px;
  text-align: left;
  display: block;
  font-weight: bold;
  border-right: 1px solid #000;
  margin-top: 10px;
}
.brand-p p {
  margin: 0;
  padding: 5px;
}

/* photo grid */
#photos {
  /* Prevent vertical gaps */
  line-height: 0;

  -webkit-column-count: 3;
  -webkit-column-gap: 0px;
  -moz-column-count: 3;
  -moz-column-gap: 0px;
  column-count: 3;
  column-gap: 0px;
}
#photos img{
    max-width: 100%;
}

#photos img:nth-child(5) {
  max-height: 175px;
  width: 65%;
}
#photos img:nth-child(2) {
  height: 225px;
}
#photos img:nth-child(4) {
  width: 178%;
  max-width: 200%;
}
.color-plates img {
  width: 80%;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.color-plates p {
  text-align: center;
  padding: 10px 0;
}

@media (max-width: 1200px) {
  #photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 1000px) {
  #photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 800px) {
  #photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
  .app-feature-card, .mobile-app-feature-card{
      margin-bottom: 30px;
  }
}
@media (max-width: 400px) {
  #photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
  .color-plates p{
      font-size: 10px;
  }
}

@media (max-width:992px){
    .screens-bg img{
      display: block;
       max-width: 60%;
       margin:0 auto 20px auto;
    }
    .screens-bg{
        margin-top: 50px;
    }
    .txt{
        color: #ff4805;
    font-size: 80px;
    text-align: left;
    display: block;
    font-weight: bold;
    border-right: 1px solid #000;
    margin-top: 10px;
    margin-bottom: 11px;
    }
    .brand-p p {
        margin: 0;
        padding: 10px;
        font-size: 24px;
    }
}

@media (max-width:600px){
    .txt{
        color: #ff4805;
    font-size: 60px;
    text-align: left;
    display: block;
    font-weight: bold;
    border-right: 1px solid #000;
    margin-top: 10px;
    margin-bottom: 11px;
    }
    .brand-p p {
        margin: 0;
        padding: 7px 0;
        font-size: 18px;
    }
    .emastertech div img{
        max-width: 50%;
    }
    .screens-bg{
        padding-bottom: 150px;
    }
    .app-features img{
        max-width: 80%;
    }
}
.emastertech {
  display: flex;
  width:100%;
  justify-content: space-around;
  margin-top: 15px;
  align-items: baseline;
}
.emastertech div {
 
    padding:10px;

}
.emastertech div img{
  width: auto;
  display: block;
 margin:0 auto;
  
}
