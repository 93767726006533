.circle { 
    width: 500px;
    height: 500px;
    margin: auto;
    margin-top: 62px;
    background-image: url("../../../images/cloudprocessimg.png");
}
.processtepscontainer{
    background-color: grey;
    color: white;
    height: 50px;
    width: 35%;
    margin: auto;
    color: white;
    margin-top: 0px;
}
.processtepscontainer p{
line-height: 1.2;
padding-top: 10px;
}
.cloudprocessrow{
    margin-top: 34%;
}
.advisoryservice{
    margin-left: -243px;
}
.managementservice{
    margin-right: -251px;
}
.migrationservice{
    margin-top: -24px;
}
.greyp {
    color: #8E8E8E29;
    font-size: 43px;
    margin-top: -55px;
    margin-bottom: 0;
    padding:0;
}
.greyp1 {
    color: #8E8E8E29;
    font-size: 43px;
    margin-left: -314px;
}
.greyp3 {
    color: #8E8E8E29;
    font-size: 43px;
    margin-right: -309px;
}
.cloudprocesscard{
    box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -webkit-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    -moz-box-shadow:0 10px 20px rgb(80 80 80 / 10%);
    min-height: 455px;
    width: 72%;
    margin: auto;
    border-radius: 22px;
    padding: 11px 30px 10px 30px;
    text-align: justify;
    margin-top: 68px;
}

#advisory{
    cursor: pointer;
}
#migration{
    cursor: pointer;
}
#management{
    cursor: pointer;
}
