.phero {
  background: 
  linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
  url("../../images/portfolio/portfolio-bg.webp") no-repeat center fixed;
  min-height: 250px; 
  background-size: auto;background-position: left top;background-repeat: repeat;background-attachment: scroll;
  background-blend-mode: luminosity;
  
}

.phero .mmcontent{
    max-width: 80%;
    margin:0 auto;
    padding: 3% 0 0 0 ;
    
}

.portfolio-cont {
  background-color: rgba(216, 218, 219, 1);
}
.portfolio-img {
  background: url("../../images/portfolio/portfolio-a.webp");
  background-size: cover !important;
  width: 100%;
  max-height: 310px;
  position: relative;
  margin: 40px auto;
  transition: all 1000ms 0s ease;
}
.portfolio-img-2 {
  background: url("../../images/portfolio/portfolio-b.webp");
}
.portfolio-img-3 {
  background: url("../../images/portfolio/portfolio-c.webp");
}

.portfolio-img-4 {
  background: url("../../images/portfolio/portfolio-d.webp");
}
.portfolio-img-5 {
  background: url("../../images/portfolio/portfolio-e.webp");
}
.portfolio-img-6 {
  background: url("../../images/portfolio/portfolio-f.webp");
}
.portfolio-img-7 {
  background: url("../../images/portfolio/portfolio-g.webp");
}
.portfolio-img-8 {
  background: url("../../images/portfolio/portfolio-h.webp");
}

.portfolio-img-9 {
  background: url("../../images/portfolio/portfolio-i.webp");
}

.portfolio-img .portfolio-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  height: 310px;
  transition: all 1000ms 0s ease;
}
.portfolio-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transition: all 1000ms 0s ease;
  width: 150px;
  margin-top: -25px; /* Half the height */
  margin-left: -75px; /* Half the width */
}
.portfolio-img:hover .portfolio-overlay {
  background-color: transparent;
}
.portfolio-img:hover .portfolio-logo {
  display: none;
}
.portfolio-img:hover .portfolio-overlay-text {
  opacity: 1;
}
.portfolio-overlay-text {
  position: absolute;
  background-color: rgba(75, 144, 222, 0.7);
  height: 80%;
  top: 10%;
  width: 80%;
  left: 10%;
  padding: 12% 6% 0 6%;
  opacity: 0;
  transition: all 500ms 0s ease !important;
}
.portfolio-overlay-text span {
  background-color: #ff4805;
  font-size: 12px;
  border-radius: 40px;
  padding: 2px 15px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  transition: all 1000ms 0s ease;
}
.portfolio-overlay-text p {
  color: #fff;
  padding-top: 7px;
  transition: all 1000ms 0s ease;
  line-height: 1.8;
}
.portfolio-overlay-text-7 {
  padding: 5% !important;
}
@media (max-width: 1200px) {
  .portfolio-overlay-text {
    height: 90%;
    top: 5%;
    width: 90%;
    left: 5%;
  }
}

@media (max-width: 770px) {
  .portfolio-cont .container {
    max-width: 95%;
    margin: 0 auto;
  }
}
@media (max-width: 330px) {
  .portfolio-img {
    width: 100%;
  }
}
