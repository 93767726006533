.story-box-container {
    background: url('../../../images/bgimage.webp');
    background-size: cover;
    padding-bottom: 90px;
   

    .container {
        padding-top: 40px;
        margin: auto;
        overflow: hidden;


        .box-container {
            margin-top: 40px;

            .card {

                min-height: 400px;
                background-color: transparent;
                border:none;

                .card-body {
                    z-index: 1;
                    font-size: 18px;
                    margin-top: 40%;
                    background-color: #428ADBDE;
                    width: 70%;
                    margin-left: 0px;
                    padding: 17px;
                    min-height: inherit;
                    color: white !important;
                    padding-top: 90px;
                    position: absolute;
                    padding-top: 31px;
                    transition: all 500ms 0s ease;
                }



                .card-img-top {
                    border-radius: 8px;
                    transition: all 500ms 0s ease;

                    &:hover {
                        transform: scale(1.1);
                    }
                }


                .card-title {
                    background-color: #FF4805;
                    border-radius: 40px;
                    color: white;
                    font-size: 12px;
                    padding: 0.3rem 1.2rem;
                    display: inline-block;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.box-container{
    position: relative;
    .btn-prev{
        position: absolute;
        top:25%;
        background: #cccccc;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        padding: 0 2px 2px 0;
        left:0;
        svg{
            height: 15px;
            margin:1px;
        }
    }
    .btn-next{
        position: absolute;
        top:25%;
        background: #cccccc;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        padding: 0 0px 2px 2px;
        right: 0;
        svg{
            height: 15px;
            margin:1px;
        }
    }
    
}



@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
    .story-box-container {
        .container-fluid {
            .box-container {
                .card {
                    .card-body {
                        z-index: 1;
                        font-size: 18px;
                        margin-top: 8%;
                        background-color: #428ADBDE;
                        width: 70%;
                        margin-left: 0;
                        padding: 17px;
                        min-height: 200px;
                        color: white !important;
                        padding-top: 0;
                        position: absolute;
                        padding-top: 31px;
                        transition: all 500ms 0s ease;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .story-box-container {
        .container-fluid {
            .box-container {
                .card {
                    .card-img-top {
                        border-radius: 0;
                    }
                    .card-body {
                        z-index: 1;
                        font-size: 18px;
                        margin-top: 0;
                        background-color: #428ADBDE;
                        width: 100%;
                        margin-left: 0;
                        padding: 17px;
                        min-height: 200px;
                        color: white !important;
                        padding-top: 0;
                        position: relative;
                        padding-top: 31px;
                        transition: all 500ms 0s ease;
                    }
                }
            }
        }
    }
}